import classNames from 'classnames';
import { JSX } from 'react';

import CheckmarkIcon from '../../../assets/icons/check-mark.svg?react';
import CrossIcon from '../../../assets/icons/cross.svg?react';

interface BooleanCheckmarkCrossProps {
  value?: boolean;
  renderCross?: boolean;
  extraClassNames?: string[];
}

export const BooleanCheckmarkCross = ({
  value,
  renderCross,
  extraClassNames
}: BooleanCheckmarkCrossProps): JSX.Element => {
  if (value) {
    return (
      <div
        className={classNames('boolean-checkmark-cross', extraClassNames)}
        data-testid="boolean-checkmark-cross-check"
      >
        <CheckmarkIcon className="boolean-checkmark-cross__checkmark-icon" />
      </div>
    );
  }

  if (value === false && renderCross) {
    return (
      <div
        className={classNames('boolean-checkmark-cross', 'boolean-checkmark-cross--cross', extraClassNames)}
        data-testid="boolean-checkmark-cross-cross"
      >
        <CrossIcon className="boolean-checkmark-cross__cross-icon" />
      </div>
    );
  }

  return <></>;
};

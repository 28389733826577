import { zodResolver } from '@hookform/resolvers/zod';
import { filter, includes, isEqual, omit, pick } from 'lodash';
import qs from 'qs';
import { JSX, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  GetConnectorHolderTypesQuery,
  GetCustomerNamesQuery,
  GetDeviceTypesQuery,
  GetProgramNamesQuery,
  GetSerialNumbersQuery,
  GetSiteNamesQuery
} from '../../../../__generated__/graphql';
import { DeviceDeactivated } from '../../../../types';
import { filterValidUrlFields } from '../../../../utilities';
import { FilterPanelButtonsGroup } from '../../../4-features';
import { RSAutocomplete } from '../../../5-elements';
import { DevicesAdminSearchParameters, devicesAdminStatesSchema } from '../devices-admin-states-schema';
import { devicesAdminFilterFields } from '../generate-queries';

interface DevicesAdminFilterPanelProps {
  serialNumbers?: GetSerialNumbersQuery;
  customerNames?: GetCustomerNamesQuery;
  siteNames?: GetSiteNamesQuery;
  programNames?: GetProgramNamesQuery;
  rocTypeNames?: GetDeviceTypesQuery;
  connectionHolderTypeNames?: GetConnectorHolderTypesQuery;
  isLoading?: boolean;
  defaultValues: DevicesAdminSearchParameters;
}

export const DevicesAdminFilterPanel = ({
  serialNumbers,
  customerNames,
  programNames,
  siteNames,
  rocTypeNames,
  connectionHolderTypeNames,
  isLoading,
  defaultValues
}: DevicesAdminFilterPanelProps): JSX.Element => {
  const { t } = useTranslation();
  const routerLocation = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
    getValues
  } = useForm<DevicesAdminSearchParameters>({
    resolver: zodResolver(devicesAdminStatesSchema),
    defaultValues
  });
  const serialNumberOptions = (serialNumbers?.devices.map((device) => device.serialNumber) as string[]) || [];
  const customerNameOptions = (customerNames?.customers.map((customer) => customer.company.name) as string[]) || [];
  const siteNameOptions = (siteNames?.sites.map((site) => site.name) as string[]) || [];
  const programNameOptions = (programNames?.programs.map((program) => program.name) as string[]) || [];
  const rocTypeNameOptions = (rocTypeNames?.deviceTypes.map((deviceType) => deviceType.modelNumber) as string[]) || [];
  const connectionHolderTypeNameOptions =
    (connectionHolderTypeNames?.connectorHolderTypes.map((type) => type.code) as string[]) || [];
  const activeOptions = Object.values(DeviceDeactivated);

  const onSubmit: SubmitHandler<DevicesAdminSearchParameters> = (data): void => {
    const newSearchParams = { ...qs.parse(searchParams.toString()), ...data, page: '1' };
    setSearchParams(new URLSearchParams(qs.stringify(newSearchParams, { arrayFormat: 'brackets' })));
    reset(data);
  };

  const onReset: SubmitHandler<DevicesAdminSearchParameters> = (): void => {
    const searchParamsObject = qs.parse(searchParams.toString());
    const searchParamsObjectNoFilter = omit(searchParamsObject, devicesAdminFilterFields);
    searchParamsObjectNoFilter.page = '1';
    setSearchParams(new URLSearchParams(qs.stringify(searchParamsObjectNoFilter, { arrayFormat: 'brackets' })));
    reset({ serialNumber: [], company: [], site: [], program: [], type: [], connectorHolderType: [], deactivated: [] });
  };

  useEffect(() => {
    const searchParameters = qs.parse(searchParams.toString());

    const validUrlFields = filterValidUrlFields<DevicesAdminSearchParameters>(
      searchParameters,
      devicesAdminStatesSchema
    );

    const filterParameters = pick(validUrlFields, devicesAdminFilterFields);
    const resetObject: DevicesAdminSearchParameters = {
      serialNumber: filterParameters.serialNumber || [],
      company: filterParameters.company || [],
      site: filterParameters.site || [],
      program: filterParameters.program || [],
      type: filterParameters.type || [],
      connectorHolderType: filterParameters.connectorHolderType || [],
      deactivated: filterParameters.deactivated || []
    };
    if (!isEqual(getValues(), resetObject)) {
      reset(resetObject);
    }
  }, [routerLocation.search]);

  return (
    <aside className="devices-admin-filter-panel" data-testid="devices-admin-filter-panel">
      <form className="devices-admin-filter-panel__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="devices-admin-filter-panel__filters">
          <Controller
            name="serialNumber"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('devicesAdminPage.filterPanel.serialNumber')}
                  data-testid="devices-admin-serial-number-autocomplete"
                  options={serialNumberOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(serialNumberOptions, valueItem))}
                  onBlur={onBlur}
                  disabled={isLoading}
                />
              );
            }}
          />
          <Controller
            name="company"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('devicesAdminPage.filterPanel.customerName')}
                  data-testid="devices-admin-company-autocomplete"
                  options={customerNameOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(customerNameOptions, valueItem))}
                  onBlur={onBlur}
                  disabled={isLoading}
                />
              );
            }}
          />
          <Controller
            name="site"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('devicesAdminPage.filterPanel.siteName')}
                  data-testid="devices-admin-site-autocomplete"
                  options={siteNameOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(siteNameOptions, valueItem))}
                  onBlur={onBlur}
                  disabled={isLoading}
                />
              );
            }}
          />
          <Controller
            name="program"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('devicesAdminPage.filterPanel.programName')}
                  data-testid="devices-admin-program-autocomplete"
                  options={programNameOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(programNameOptions, valueItem))}
                  onBlur={onBlur}
                  disabled={isLoading}
                />
              );
            }}
          />
          <Controller
            name="type"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('devicesAdminPage.filterPanel.rocTypeName')}
                  data-testid="devices-admin-type-autocomplete"
                  options={rocTypeNameOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(rocTypeNameOptions, valueItem))}
                  onBlur={onBlur}
                  disabled={isLoading}
                />
              );
            }}
          />
          <Controller
            name="connectorHolderType"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('devicesAdminPage.filterPanel.connectorHolderTypeName')}
                  data-testid="devices-admin-connector-holder-type-autocomplete"
                  options={connectionHolderTypeNameOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(connectionHolderTypeNameOptions, valueItem))}
                  onBlur={onBlur}
                  disabled={isLoading}
                />
              );
            }}
          />
          <Controller
            name="deactivated"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('devicesAdminPage.filterPanel.deactivated')}
                  data-testid="devices-admin-deactivated-autocomplete"
                  options={activeOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(activeOptions, valueItem))}
                  onBlur={onBlur}
                  disabled={isLoading}
                />
              );
            }}
          />
        </div>
        <FilterPanelButtonsGroup
          handleApply={handleSubmit(onSubmit)}
          handleClearAll={handleSubmit(onReset)}
          isApplyDisabled={!isDirty}
          variant="admin"
        />
      </form>
    </aside>
  );
};

import { isEmpty, uniq } from 'lodash';

import { UserBoolExp } from '../../../../__generated__/graphql';
import { mapIsSuperUser } from '../../../../utilities';
import { UsersAdminSearchParameters } from '../users-admin-states-schema';

export const usersAdminFilterFields = [
  'name',
  'companyName',
  'companyType',
  'userType',
  'permissions',
  'superUser'
] as const;

type UsersAdminFilterField = (typeof usersAdminFilterFields)[number];

export const generateFilterQuery = (
  filterParameters: Pick<UsersAdminSearchParameters, UsersAdminFilterField>
): UserBoolExp => {
  const filterQuery: UserBoolExp = { _and: [] };
  // We use UUID to filter, as neither first name nor last name is unique
  if (filterParameters.name) {
    filterQuery._and!.push({ id: { _in: uniq(filterParameters.name) } });
  }
  if (filterParameters.companyName) {
    filterQuery._and!.push({ company: { name: { _in: uniq(filterParameters.companyName) } } });
  }
  if (filterParameters.companyType) {
    filterQuery._and!.push({ company: { companyType: { _in: uniq(filterParameters.companyType) } } });
  }
  if (filterParameters.userType) {
    filterQuery._and!.push({ userType: { _in: uniq(filterParameters.userType) } });
  }
  if (filterParameters.permissions) {
    filterQuery._and!.push({ userPermissionLinks: { userPermission: { _in: uniq(filterParameters.permissions) } } });
  }
  if (filterParameters.superUser) {
    const uniqueSuperUserFilterValue = uniq(filterParameters.superUser);
    if (uniqueSuperUserFilterValue.length === 1) {
      filterQuery._and!.push({ isSuperUser: { _eq: mapIsSuperUser(uniqueSuperUserFilterValue[0]) } });
    }
  }

  if (isEmpty(filterQuery._and)) {
    return {};
  }
  return filterQuery;
};

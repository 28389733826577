import { gql } from '../../../__generated__/gql';

export const SUBSCRIPTION_CUSTOMERS_AGGREGATE = gql(`
  subscription subscribeCustomersAggregate($filters: CustomerBoolExp) {
    customersAggregate(where: $filters) {
      aggregate {
        count
      }
    }
  }
`);

import { gql } from '../../../__generated__/gql';

export const SUBSCRIPTION_OPERATIONS_AGGREGATE = gql(`
  subscription subscribeOperationsAggregate($filters: DeviceOperationBoolExp) {
    deviceOperationsAggregate(where: $filters) {
      aggregate {
        count
      }
    }
  }
`);

import { useQuery } from '@apollo/client';
import { GridPaginationModel } from '@mui/x-data-grid';
import { get, isEqual, pick, toNumber, toString } from 'lodash';
import qs from 'qs';
import { JSX, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { devicesAdminDataGridColumns, generateDevicesAdminRows } from './data-grid-configurations';
import { DevicesAdminSearchParameters, devicesAdminStatesSchema } from './devices-admin-states-schema';
import { devicesAdminFilterFields, generateFilterQuery, generateSortQuery } from './generate-queries';
import { DeviceBoolExp, DeviceOrderBy } from '../../../__generated__/graphql';
import ReloadIcon from '../../../assets/icons/reload.svg?react';
import { DEFAULT_GET_ADMIN_DEVICES_SORT_BY, DEFAULT_PAGE_SIZE } from '../../../constants';
import {
  QUERY_GET_ADMIN_DEVICES,
  QUERY_GET_CONNECTOR_HOLDER_TYPES,
  QUERY_GET_CUSTOMER_NAMES,
  QUERY_GET_DEVICE_TYPES,
  QUERY_GET_PROGRAM_NAMES,
  QUERY_GET_SERIAL_NUMBERS,
  QUERY_GET_SITE_NAMES
} from '../../../services/queries';
import {
  calculatePaginationEndRow,
  calculatePaginationPageCount,
  calculatePaginationStartRow,
  filterValidUrlFields
} from '../../../utilities';
import { AdminOverviewMainSectionWrapper } from '../../2-templates';
import { AdminAddActionButton, AdminPageTitle, PaginationBar, RSDataGrid } from '../../4-features';
import { UserTimezoneContext } from '../../contexts';
import { ErrorPage } from '../error-page';
import { DevicesAdminFilterPanel } from './devices-admin-filter-panel';
import { RSButton } from '../../5-elements';

export const DevicesAdminPage = (): JSX.Element => {
  const { t } = useTranslation();
  const routerLocation = useLocation();
  const { userTimezone } = useContext(UserTimezoneContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 1, pageSize: DEFAULT_PAGE_SIZE });
  const [sortingOptions, setSortingOptions] = useState<DeviceOrderBy[]>(DEFAULT_GET_ADMIN_DEVICES_SORT_BY);
  const [filterOptions, setFilterOptions] = useState<DeviceBoolExp>({});
  const { data, loading, error } = useQuery(QUERY_GET_ADMIN_DEVICES, {
    variables: {
      offset: (paginationModel.page - 1) * DEFAULT_PAGE_SIZE,
      limit: DEFAULT_PAGE_SIZE,
      orderBy: sortingOptions,
      filters: filterOptions
    }
  });
  const {
    loading: loadingSerialNumbers,
    data: dataSerialNumbers,
    error: errorSerialNumbers
  } = useQuery(QUERY_GET_SERIAL_NUMBERS, { fetchPolicy: 'network-only' });
  const {
    loading: loadingCustomerNames,
    data: dataCustomerNames,
    error: errorCustomerNames
  } = useQuery(QUERY_GET_CUSTOMER_NAMES, { fetchPolicy: 'network-only' });
  const {
    loading: loadingSiteNames,
    data: dataSiteNames,
    error: errorSiteNames
  } = useQuery(QUERY_GET_SITE_NAMES, { fetchPolicy: 'network-only' });
  const {
    loading: loadingProgramNames,
    data: dataProgramNames,
    error: errorProgramNames
  } = useQuery(QUERY_GET_PROGRAM_NAMES, { fetchPolicy: 'network-only' });
  const {
    loading: loadingRocTypeNames,
    data: dataRocTypeNames,
    error: errorRocTypeNames
  } = useQuery(QUERY_GET_DEVICE_TYPES, { fetchPolicy: 'network-only' });
  // dataConnectorHolderTypeNames
  const {
    loading: loadingConnectorHolderTypeNames,
    data: dataConnectorHolderTypeNames,
    error: errorConnectorHolderTypeNames
  } = useQuery(QUERY_GET_CONNECTOR_HOLDER_TYPES, { fetchPolicy: 'network-only' });
  const searchParameters = qs.parse(searchParams.toString());
  const validUrlFields = filterValidUrlFields<DevicesAdminSearchParameters>(searchParameters, devicesAdminStatesSchema);

  useEffect(() => {
    // Set page
    const pageNumber = toNumber(get(validUrlFields, 'page')) || 1;
    if (paginationModel.page !== pageNumber) {
      setPaginationModel((prevModel) => ({ ...prevModel, page: pageNumber }));
    }

    // Set sorting
    const sortParameter = get(validUrlFields, 'sort');
    const sortQuery = generateSortQuery(sortParameter);
    if (!isEqual(sortQuery, sortingOptions)) {
      setSortingOptions(sortQuery);
    }

    // Set filtering
    const filterParameters = pick(validUrlFields, devicesAdminFilterFields);
    const filterQuery = generateFilterQuery(filterParameters);
    if (!isEqual(filterQuery, filterOptions)) {
      setFilterOptions(filterQuery);
    }
  }, [routerLocation.search]);

  const groupedError =
    error ||
    errorSerialNumbers ||
    errorCustomerNames ||
    errorSiteNames ||
    errorProgramNames ||
    errorRocTypeNames ||
    errorConnectorHolderTypeNames;
  if (groupedError) {
    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={groupedError.message}
      />
    );
  }

  const devicesCount = data?.devicesAggregate.aggregate?.count;
  const isLoading =
    loading ||
    loadingSerialNumbers ||
    loadingCustomerNames ||
    loadingSiteNames ||
    loadingProgramNames ||
    loadingRocTypeNames ||
    loadingConnectorHolderTypeNames;

  return (
    <AdminOverviewMainSectionWrapper
      title={
        <AdminPageTitle
          titleName={t('devicesAdminPage.title')}
          actions={
            <>
              <RSButton
                extraClassNames={['devices-admin-page__sync-program']}
                disabled={true}
                data-testid="devices-admin-page-sync-programs"
                color="success"
              >
                <ReloadIcon />
                {t('devicesAdminPage.syncPrograms')}
              </RSButton>
              <AdminAddActionButton
                titleName={t('devicesAdminPage.addDevice')}
                disabled={true}
                data-testid="devices-admin-page-add-device"
              />
            </>
          }
        />
      }
      filter={
        <DevicesAdminFilterPanel
          isLoading={isLoading}
          serialNumbers={dataSerialNumbers}
          customerNames={dataCustomerNames}
          siteNames={dataSiteNames}
          programNames={dataProgramNames}
          rocTypeNames={dataRocTypeNames}
          connectionHolderTypeNames={dataConnectorHolderTypeNames}
          defaultValues={{
            serialNumber: validUrlFields.serialNumber,
            company: validUrlFields.company,
            site: validUrlFields.site,
            program: validUrlFields.program,
            type: validUrlFields.type,
            connectorHolderType: validUrlFields.connectorHolderType,
            deactivated: validUrlFields.deactivated
          }}
        />
      }
      data-testid="devices-admin-page-wrapper"
    >
      <section className="devices-admin-page" data-testid="devices-admin-page">
        <div className="devices-admin-page__data-grid-container">
          <RSDataGrid
            columns={devicesAdminDataGridColumns}
            rows={generateDevicesAdminRows(data?.devices, userTimezone)}
            loading={isLoading}
            data-testid="devices-admin-page-data-grid"
          />
        </div>
        <div className="devices-admin-page__pagination">
          <PaginationBar
            isLoading={isLoading}
            startRow={calculatePaginationStartRow(paginationModel.page, DEFAULT_PAGE_SIZE)}
            endRow={calculatePaginationEndRow(paginationModel.page, DEFAULT_PAGE_SIZE, devicesCount)}
            rowCount={devicesCount}
            pageCount={calculatePaginationPageCount(devicesCount)}
            onChange={(_event, page) => {
              const originalSearchParamsObject = qs.parse(searchParams.toString());
              setSearchParams(
                qs.stringify({ ...originalSearchParamsObject, page: toString(page) }, { arrayFormat: 'brackets' })
              );
            }}
            page={paginationModel.page}
          />
        </div>
      </section>
    </AdminOverviewMainSectionWrapper>
  );
};

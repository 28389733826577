import { UserType } from '../../__generated__/graphql';
import i18n from '../../i18n';

export const mapUserType = (userType: UserType): string => {
  switch (userType) {
    case UserType.PortalUser:
      return i18n.t('displayUserType.portal');
    case UserType.RocsysApiUser:
      return i18n.t('displayUserType.api');
    default:
      return i18n.t('noData');
  }
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/content-en.json';

const translationResources = {
  en: { translation: translationEN }
};

i18n.use(initReactI18next).init({
  resources: translationResources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: { escapeValue: false }
});

export default i18n;

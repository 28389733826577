import { faker } from '@faker-js/faker';

import { GetAdminDevicesQuery, OperationalLifeCycle } from '../../../__generated__/graphql';

const fakerGeneratedAdminDevices = (): GetAdminDevicesQuery['devices'] => {
  const devices: GetAdminDevicesQuery['devices'] = [];
  for (let i = 0; i < 90; i += 1) {
    const deviceId = faker.string.uuid();
    devices.push({
      id: deviceId,
      serialNumber: `ROC${faker.number.int({ min: 100000, max: 999999 }).toString().padStart(6, '0')}`,
      operationalLifeCycle: OperationalLifeCycle.Operational,
      createdAt: faker.date.past().toISOString(),
      updatedAt: faker.date.past().toISOString(),
      connectorHolderType: { id: faker.string.uuid(), code: faker.helpers.arrayElement(['CHAdeMO', 'CCS', 'Type 2']) },
      deviceType: { id: faker.string.uuid(), modelNumber: faker.company.name() },
      site: {
        id: faker.string.uuid(),
        name: faker.company.name(),
        customer: { companyId: faker.string.uuid(), company: { id: faker.string.uuid(), name: faker.company.name() } }
      },
      program: { id: faker.string.uuid(), name: faker.company.name() },
      deviceGroupDevicesAggregate: { aggregate: { count: faker.number.int({ min: 0, max: 5 }) } }
    });
  }
  return devices;
};

export const fakerGeneratedAdminDevicesResponse: GetAdminDevicesQuery = {
  devicesAggregate: { aggregate: { count: 90 } },
  devices: fakerGeneratedAdminDevices()
};

export const getAdminDevicesResponse: GetAdminDevicesQuery = {
  devices: [
    {
      id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d',
      serialNumber: 'ROC00000001',
      operationalLifeCycle: OperationalLifeCycle.Operational,
      createdAt: '2024-12-02T08:48:23.42+00:00',
      updatedAt: '2024-12-02T08:48:23.42+00:00',
      deviceGroupDevicesAggregate: { aggregate: { count: 0 } },
      connectorHolderType: { id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d', code: 'CHAdeMO' },
      deviceType: { id: 'cf85f3e1-0f74-4fd4-84e4-7c78485f8be5', modelNumber: 'RCS1-RBT-01-EU' },
      site: {
        id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d',
        name: 'ABB service',
        customer: {
          companyId: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d',
          company: { id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d', name: 'ABB service' }
        }
      },
      program: { id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d', name: '1234XYZ' }
    },
    {
      id: '3b802b17-e7e3-4300-a554-14db1419a5c4',
      serialNumber: 'ROC00000002',
      operationalLifeCycle: OperationalLifeCycle.Operational,
      createdAt: '2024-12-02T08:48:23.42+00:00',
      updatedAt: '2024-12-02T08:48:23.42+00:00',
      deviceGroupDevicesAggregate: { aggregate: { count: 0 } },
      connectorHolderType: { id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d', code: 'CHAdeMO' },
      deviceType: { id: 'cf85f3e1-0f74-4fd4-84e4-7c78485f8be5', modelNumber: 'RCS1-RBT-01-EU' },
      site: {
        id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d',
        name: 'ABB service',
        customer: {
          companyId: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d',
          company: { id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d', name: 'ABB service' }
        }
      },
      program: { id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d', name: '1234XYZ' }
    },
    {
      id: 'd8d9bbab-af09-4179-a256-1ede0d039d28',
      serialNumber: 'ROC00000003',
      operationalLifeCycle: OperationalLifeCycle.Operational,
      createdAt: '2024-12-02T08:48:23.42+00:00',
      updatedAt: '2024-12-02T08:48:23.42+00:00',
      deviceGroupDevicesAggregate: { aggregate: { count: 0 } },
      connectorHolderType: { id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d', code: 'CHAdeMO' },
      deviceType: { id: 'cf85f3e1-0f74-4fd4-84e4-7c78485f8be5', modelNumber: 'RCS1-RBT-01-EU' },
      site: {
        id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d',
        name: 'ABB service',
        customer: {
          companyId: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d',
          company: { id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d', name: 'ABB service' }
        }
      },
      program: { id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d', name: '1234XYZ' }
    },
    {
      id: '5463774e-634d-4ecf-a89f-60b9b0a1f1fb',
      serialNumber: 'ROC00000004',
      operationalLifeCycle: OperationalLifeCycle.Operational,
      createdAt: '2024-12-02T08:48:23.42+00:00',
      updatedAt: '2024-12-02T08:48:23.42+00:00',
      deviceGroupDevicesAggregate: { aggregate: { count: 0 } },
      connectorHolderType: { id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d', code: 'CHAdeMO' },
      deviceType: { id: 'cf85f3e1-0f74-4fd4-84e4-7c78485f8be5', modelNumber: 'RCS1-RBT-01-EU' },
      site: {
        id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d',
        name: 'ABB service',
        customer: {
          companyId: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d',
          company: { id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d', name: 'ABB service' }
        }
      },
      program: { id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d', name: '1234XYZ' }
    },
    {
      id: '8b1b1b1b-1984-4cac-9a4a-ec602d2f626d',
      serialNumber: 'ROC00000005',
      operationalLifeCycle: OperationalLifeCycle.Operational,
      createdAt: '2024-12-02T08:48:23.42+00:00',
      updatedAt: '2024-12-02T08:48:23.42+00:00',
      deviceGroupDevicesAggregate: { aggregate: { count: 0 } },
      connectorHolderType: { id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d', code: 'CHAdeMO' },
      deviceType: { id: 'cf85f3e1-0f74-4fd4-84e4-7c78485f8be5', modelNumber: 'RCS1-RBT-01-EU' },
      site: {
        id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d',
        name: 'ABB service',
        customer: {
          companyId: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d',
          company: { id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d', name: 'ABB service' }
        }
      },
      program: { id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d', name: '1234XYZ' }
    }
  ],
  devicesAggregate: { aggregate: { count: 5 } }
};

import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { AdminNavMenuButton, AdminNavMenuButtonProps } from './admin-nav-menu-button';
import { COMPANIES_ADMIN_PAGE_PATH, DEVICES_ADMIN_PAGE_PATH, USERS_ADMIN_PAGE_PATH } from '../../../../constants';

export const AdminNavMenu = (): JSX.Element => {
  const { t } = useTranslation();

  const menuButtons: AdminNavMenuButtonProps[] = [
    {
      key: 'companies',
      'data-testid': 'admin-nav-menu-button-companies',
      buttonLabel: t('adminNavMenu.companies'),
      navLinkProps: { to: COMPANIES_ADMIN_PAGE_PATH }
    },
    {
      key: 'users',
      'data-testid': 'admin-nav-menu-button-users',
      buttonLabel: t('adminNavMenu.users'),
      navLinkProps: { to: USERS_ADMIN_PAGE_PATH }
    },
    {
      key: 'devices',
      'data-testid': 'admin-nav-menu-button-devices',
      buttonLabel: t('adminNavMenu.devices'),
      navLinkProps: { to: DEVICES_ADMIN_PAGE_PATH }
    }
  ];

  return (
    <nav className="admin-nav-menu">
      {menuButtons.map((menuItem) => (
        <AdminNavMenuButton {...menuItem} key={menuItem.key} />
      ))}
    </nav>
  );
};

import { ACDCycleOperationResultSuccessRates } from '../../types';
import { calculateChangePercentage } from '../calculate-change-percentage';
import { calculateSuccessPercentage } from '../calculate-success-percentage';
import { execIfBothDefined } from '../exec-if-both-defined';

export const calculateACDCycleOperationsResultSuccessRates = (
  acdCycleOperationsSuccess: number | null,
  acdCycleOperationsFailed: number | null,
  acdCycleOperationsPrevSuccess: number | null,
  acdCycleOperationsPrevFailed: number | null
): ACDCycleOperationResultSuccessRates => {
  const acdCycleOperationsSuccessRate = calculateSuccessPercentage(acdCycleOperationsFailed, acdCycleOperationsSuccess);

  const acdCycleOperationsPrevSuccessRate = calculateSuccessPercentage(
    acdCycleOperationsPrevFailed,
    acdCycleOperationsPrevSuccess
  );

  const acdCycleOperationsRatesBothDefined =
    acdCycleOperationsSuccessRate !== null && acdCycleOperationsPrevSuccessRate !== null;
  const acdCycleOperationsSuccessRateChange = acdCycleOperationsRatesBothDefined
    ? acdCycleOperationsSuccessRate - acdCycleOperationsPrevSuccessRate
    : null;
  const acdCycleOperationsCount = execIfBothDefined(
    acdCycleOperationsSuccess,
    acdCycleOperationsFailed,
    (val1, val2) => val1 + val2
  );

  const acdCycleOperationsPrevCount = execIfBothDefined(
    acdCycleOperationsPrevSuccess,
    acdCycleOperationsPrevFailed,
    (val1, val2) => val1 + val2
  );

  const acdCycleOperationsCountChange = calculateChangePercentage(acdCycleOperationsPrevCount, acdCycleOperationsCount);

  return {
    acdCycleOperationsSuccessRate,
    acdCycleOperationsSuccessRateChange,
    acdCycleOperationsCount,
    acdCycleOperationsCountChange
  };
};

import { faker } from '@faker-js/faker';

import { GetAdminCompaniesQuery } from '../../../__generated__/graphql';
import { CompanyType } from '../../../types';

const fakerGeneratedAdminCompanies = (): GetAdminCompaniesQuery['companies'] => {
  const companies: GetAdminCompaniesQuery['companies'] = [];
  for (let i = 0; i < 90; i += 1) {
    const companyId = faker.string.uuid();
    const companyType = faker.helpers.enumValue(CompanyType);
    companies.push({
      id: companyId,
      name: faker.company.name(),
      companyType: companyType,
      createdAt: faker.date.past().toISOString(),
      updatedAt: faker.date.past().toISOString(),
      usersAggregate: { aggregate: { count: faker.number.int({ min: 0, max: 100 }) } },
      customer:
        companyType === CompanyType.ServiceProvider
          ? null
          : {
              companyId: companyId,
              allowImport: faker.datatype.boolean(),
              sitesAggregate: { aggregate: { count: faker.number.int({ min: 0, max: 100 }) } },
              devicesAggregate: { aggregate: { count: faker.number.int({ min: 0, max: 100 }) } }
            }
    });
  }
  return companies;
};

export const fakerGeneratedAdminCompaniesResponse: GetAdminCompaniesQuery = {
  companiesAggregate: { aggregate: { count: 90 } },
  companies: fakerGeneratedAdminCompanies()
};

export const getAdminCompaniesResponse: GetAdminCompaniesQuery = {
  companies: [
    {
      id: '47a1d1ac-1984-4cac-9a4a-ec602d2f626d',
      name: 'ABB service',
      companyType: 'SERVICE_PROVIDER',
      createdAt: '2024-12-02T08:48:23.42+00:00',
      updatedAt: '2024-12-02T08:48:23.42+00:00',
      usersAggregate: { aggregate: { count: 0 } },
      customer: null
    },
    {
      id: '3b802b17-e7e3-4300-a554-14db1419a5c4',
      name: 'Good EV Comp',
      companyType: 'CUSTOMER',
      createdAt: '2024-12-02T08:48:23.425+00:00',
      updatedAt: '2024-12-02T08:48:23.425+00:00',
      usersAggregate: { aggregate: { count: 0 } },
      customer: {
        companyId: '3b802b17-e7e3-4300-a554-14db1419a5c4',
        allowImport: false,
        sitesAggregate: { aggregate: { count: 1 } },
        devicesAggregate: { aggregate: { count: 95 } }
      }
    },
    {
      id: 'd8d9bbab-af09-4179-a256-1ede0d039d28',
      name: 'Rocsys Production Factory',
      companyType: 'CUSTOMER',
      createdAt: '2024-12-02T08:48:23.423+00:00',
      updatedAt: '2024-12-02T08:48:23.423+00:00',
      usersAggregate: { aggregate: { count: 7 } },
      customer: {
        companyId: 'd8d9bbab-af09-4179-a256-1ede0d039d28',
        allowImport: true,
        sitesAggregate: { aggregate: { count: 2 } },
        devicesAggregate: { aggregate: { count: 3 } }
      }
    },
    {
      id: '3a10fb6b-8a4d-4e9c-aa69-c48249e69e31',
      name: 'Rocsys Service & Applications',
      companyType: 'SERVICE_PROVIDER',
      createdAt: '2024-12-02T08:48:23.413+00:00',
      updatedAt: '2024-12-02T08:48:23.413+00:00',
      usersAggregate: { aggregate: { count: 2 } },
      customer: null
    },
    {
      id: '5463774e-634d-4ecf-a89f-60b9b0a1f1fb',
      name: 'Unsed service',
      companyType: 'SERVICE_PROVIDER',
      createdAt: '2024-12-02T08:48:23.422+00:00',
      updatedAt: '2024-12-02T08:48:23.422+00:00',
      usersAggregate: { aggregate: { count: 1 } },
      customer: null
    }
  ],
  companiesAggregate: { aggregate: { count: 5 } }
};

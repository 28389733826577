import { gql } from '../../../__generated__/gql';

export const SUBSCRIPTION_DEVICE_DETAILS = gql(`
  subscription subscribeDeviceDetails($deviceId: uuid!) {
    deviceByPK(id: $deviceId) {
      id
      serialNumber
      operationalLifeCycle
      balenaUid
      description
      remarks
      deviceType {
        id
        name
      }
      deviceImages {
        id
      }
      deviceCurrentPerformance {
        deviceId
        successLastPeriod
        totalLastPeriod
        successPrevPeriod
        totalPrevPeriod
      }
      deviceOperations(limit: 5, orderBy: { startAt: DESC }, where: { operationName: { _eq: "ACDCycle" } }) {
        id
        sequence
        startAt
        endAt
        operationalLifeCycle
        success
        errorDetails
        isAnnotated
        deviceOperationResult {
          id
          isSuccessful
          name
          code
          updatedAt
        }
        deviceOperationAnnotatedResult {
          id
          isSuccessful
          name
          code
          updatedAt
        }
        deviceOperationFinalResult {
          id
          isSuccessful
          name
          code
          updatedAt
        }
        deviceOperationAnnotationAudits(orderBy: { annotatedAt: DESC }) {
          id
          annotatedAt
        }
      }
      site {
        id
        name
        timezone {
          name
        }
        location {
          id
          latitude
          longitude
        }
        customer {
          companyId
          company {
            id
            name
          }
        }
      }
      program {
        id
        name
      }
      deviceSoftwareConfigurationActive {
        id
        rocosComposedVersion
        balenaOsVariant
        balenaOsVersion
        balenaReleaseCommit
        balenaSupervisorVersion
        releaseTagGitDescribe
      }
      location {
        id
        longitude
        latitude
      }
      deviceCloudSettings {
        id
        autoImageCollectionEnabled
        autoLogCollectionEnabled
        autoLogCollectionOnSuccessEnabled
        notificationsEnabled
        notificationsOnFailedCycleEnabled
        rocsysApiEnabled
        deviceMeasurementsRetentionDays
        deviceOperationsRetentionDays
        vpnEnabled
      }
      connectorHolderType {
        id
        connectorType
        name
      }
      deviceLastState {
        id
        deviceStatus
        isActive
        stateName
        updatedAt
      }
      deviceMeasurementValues(
        where: {
          deviceMeasurementProperty: {
            name: {
              _in: [
                "is_online"
                "memory_usage"
                "memory_total"
                "storage_usage"
                "storage_total"
                "safety_triggered"
                "remote_queue_length"
              ]
            }
          }
          endAt: { _isNull: true }
        }
      ) {
        id
        value
        updatedAt
        status
        deviceMeasurementProperty {
          id
          name
          unit
        }
      }
    }
  }
`);

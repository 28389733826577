import { gql } from '../../../../__generated__/gql';

export const QUERY_GET_ADMIN_USERS = gql(`
  query getAdminUsers($offset: Int, $limit: Int, $filters: UserBoolExp, $orderBy: [UserOrderBy!]) {
    users(orderBy: $orderBy, offset: $offset, limit: $limit, where: $filters) {
      id
      firstName
      lastName
      email
      userType
      isSuperUser
      userPermissionLinks {
        id
        userPermission
      }
      userDeviceGroupsAggregate {
        aggregate {
          count
        }
      }
      createdAt
      updatedAt
      company {
        id
        name
        companyType
      }
    }
    usersAggregate {
      aggregate {
        count
      }
    }
  }
`);

import { gql } from '../../../__generated__/gql';

export const SUBSCRIPTION_DEVICE_CURRENT_PERFORMANCE_AGGREGATE = gql(`
  subscription subscribeDeviceCurrentPerformanceAggregate($filters: DeviceBoolExp) {
    deviceCurrentPerformanceAggregate(where: { device: $filters }) {
      aggregate {
        sum {
          totalLastPeriod
          totalPrevPeriod
          successPrevPeriod
          successLastPeriod
        }
      }
    }
  }
`);

import { isEmpty, uniq } from 'lodash';

import { DeviceBoolExp } from '../../../../__generated__/graphql';
import { mapDeviceDeactivated } from '../../../../utilities';
import { DevicesAdminSearchParameters } from '../devices-admin-states-schema';

export const devicesAdminFilterFields = [
  'serialNumber',
  'company',
  'site',
  'program',
  'type',
  'connectorHolderType',
  'deactivated'
] as const;

type DevicesAdminFilterField = (typeof devicesAdminFilterFields)[number];

export const generateFilterQuery = (
  filterParameters: Pick<DevicesAdminSearchParameters, DevicesAdminFilterField>
): DeviceBoolExp => {
  const filterQuery: DeviceBoolExp = { _and: [] };
  if (filterParameters.serialNumber) {
    filterQuery._and!.push({ serialNumber: { _in: uniq(filterParameters.serialNumber) } });
  }
  if (filterParameters.company) {
    filterQuery._and!.push({ customer: { company: { name: { _in: uniq(filterParameters.company) } } } });
  }
  if (filterParameters.site) {
    filterQuery._and!.push({ site: { name: { _in: uniq(filterParameters.site) } } });
  }
  if (filterParameters.program) {
    filterQuery._and!.push({ program: { name: { _in: uniq(filterParameters.program) } } });
  }
  if (filterParameters.type) {
    filterQuery._and!.push({ deviceType: { modelNumber: { _in: uniq(filterParameters.type) } } });
  }
  if (filterParameters.connectorHolderType) {
    filterQuery._and!.push({ connectorHolderType: { code: { _in: uniq(filterParameters.connectorHolderType) } } });
  }
  if (filterParameters.deactivated) {
    const uniqueActiveFilterValue = uniq(filterParameters.deactivated);
    if (uniqueActiveFilterValue.length === 1) {
      filterQuery._and!.push({ deactivated: { _eq: mapDeviceDeactivated(uniqueActiveFilterValue[0]) } });
    }
  }

  if (isEmpty(filterQuery._and)) {
    return {};
  }
  return filterQuery;
};

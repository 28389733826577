import { Dispatch, SetStateAction, createContext } from 'react';

export interface SubscriptionModeContextInterface {
  subscriptionMode: boolean;
  setSubscriptionMode: Dispatch<SetStateAction<boolean>>;
}

export const SubscriptionModeContext = createContext<SubscriptionModeContextInterface>({
  subscriptionMode: false,
  setSubscriptionMode: () => {}
});

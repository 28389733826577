import { gql } from '../../../__generated__/gql';

export const SUBSCRIPTION_DEVICES_AGGREGATE = gql(`
  subscription subscribeDevicesAggregate($filters: DeviceBoolExp) {
    devicesAggregate(where: $filters) {
      aggregate {
        count
      }
    }
  }
`);

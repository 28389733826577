import { gql } from '../../../../__generated__/gql';

export const QUERY_GET_ADMIN_COMPANIES = gql(`
  query getAdminCompanies($offset: Int, $limit: Int, $filters: CompanyBoolExp, $orderBy: [CompanyOrderBy!]) {
    companies(orderBy: $orderBy, offset: $offset, limit: $limit, where: $filters) {
      id
      name
      companyType
      createdAt
      updatedAt
      usersAggregate {
        aggregate {
          count
        }
      }
      customer {
        companyId
        allowImport
        sitesAggregate {
          aggregate {
            count
          }
        }
        devicesAggregate {
          aggregate {
            count
          }
        }
      }
    }
    companiesAggregate(where: $filters) {
      aggregate {
        count
      }
    }
  }
`);

import { useQuery } from '@apollo/client';
import { InputLabel, MenuItem, MenuItemProps, Select } from '@mui/material';
import { includes, join, split, uniq, uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import qs from 'qs';
import { JSX, useContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { TimezoneSearchParameters, timezoneSchema } from './timezone-schema';
import { GetSiteTimezonesQuery } from '../../../__generated__/graphql';
import AngleBracketDownIcon from '../../../assets/icons/angle-brackets-down.svg?react';
import WorldClockIcon from '../../../assets/icons/world-clock.svg?react';
import { QUERY_GET_SITE_TIMEZONES } from '../../../services/queries';
import { Flatten } from '../../../types';
import { browserTimezone, filterValidUrlFields, getBrowserLocale, validateTimezone } from '../../../utilities';
import { UserTimezoneContext } from '../../contexts';

interface TimezoneSelectMenuItemProps extends Omit<MenuItemProps, 'children'> {
  displayName: string;
  abbreviation: string;
  time: string;
}

export const TimezoneSelect = (): JSX.Element => {
  const { t } = useTranslation();
  const { userTimezone, setUserTimezone } = useContext(UserTimezoneContext);
  const routerLocation = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loading, data } = useQuery(QUERY_GET_SITE_TIMEZONES);
  // When opening the select menu, we want the time to be updated
  // https://legacy.reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const searchParameters = qs.parse(searchParams.toString());
  const validUrlFields = filterValidUrlFields<TimezoneSearchParameters>(searchParameters, timezoneSchema);

  const getTimezoneNamesFromSites = (sites: GetSiteTimezonesQuery['sites']) => {
    return sites.map((site) => ({
      timezoneName: site.timezone.name,
      timezoneAbbreviation: site.timezone.timezoneInfo?.abbrev
    }));
  };

  type TimezoneNameType = Flatten<ReturnType<typeof getTimezoneNamesFromSites>>;

  const generateTimezoneSelectOption = (timezoneName: TimezoneNameType): TimezoneSelectMenuItemProps => {
    return {
      displayName: join(split(timezoneName.timezoneName, '/'), ' / '),
      value: timezoneName.timezoneName,
      time: DateTime.local({ zone: timezoneName.timezoneName })
        .setLocale(getBrowserLocale() || 'en-GB')
        .toFormat('HH:mm'),
      abbreviation:
        timezoneName.timezoneAbbreviation ||
        DateTime.local({ zone: timezoneName.timezoneName })
          .setLocale(getBrowserLocale() || 'en-GB')
          .toFormat('ZZZZ')
    };
  };

  useEffect(() => {
    if (includes(routerLocation.pathname, 'portal/callback')) {
      return;
    }

    const timezoneFromUrl = validUrlFields.timezone;
    // Set user timezone
    if (!timezoneFromUrl) {
      searchParams.set('timezone', validateTimezone(userTimezone) ? userTimezone : browserTimezone);
      setSearchParams(searchParams, { replace: true });
      return;
    }

    if (timezoneFromUrl !== userTimezone && data) {
      const siteTimezoneNames = data.sites.map((site) => site.timezone.name);
      const uniqueValidTimezoneNames = uniq([...siteTimezoneNames, browserTimezone, 'UTC']);
      if (includes(uniqueValidTimezoneNames, timezoneFromUrl)) {
        // If this is a valid timezone from the option menu
        setUserTimezone(timezoneFromUrl);
      } else {
        // If the timezone string is valid but not in the timezone option menu: set it to browser timezone
        searchParams.set('timezone', browserTimezone);
        setSearchParams(searchParams, { replace: true });
      }
    }
  }, [routerLocation.search, loading]);

  if (loading) {
    return <></>;
  }

  const timezones: TimezoneNameType[] = uniqBy(
    [
      {
        timezoneName: browserTimezone,
        timezoneAbbreviation: DateTime.local({ zone: browserTimezone })
          .setLocale(getBrowserLocale() || 'en-GB')
          .toFormat('ZZZZ')
      },
      { timezoneName: 'UTC', timezoneAbbreviation: 'UTC' },
      ...getTimezoneNamesFromSites(data?.sites || [])
    ],
    'timezoneName'
  );
  const timezoneOptions = timezones.map((timezone) => generateTimezoneSelectOption(timezone));

  return (
    <div className="timezone-select" data-testid="timezone-select">
      <InputLabel shrink={true} className="timezone-select__input-label" data-testid="timezone-select-input-label">
        {t('timezoneSelect.timezone')}
      </InputLabel>
      <div className="timezone-select__menu">
        <div className="timezone-select__menu-icon">
          <WorldClockIcon />
        </div>
        <Select
          className="timezone-select__select"
          data-testid="timezone-select-menu"
          IconComponent={AngleBracketDownIcon}
          disabled={loading}
          onOpen={forceUpdate}
          value={userTimezone}
          onChange={(event) => {
            setUserTimezone(event.target.value);
            searchParams.set('timezone', event.target.value);
            setSearchParams(searchParams);
          }}
          MenuProps={{
            sx: { top: '2.1rem', left: '-0.9rem' },
            slotProps: {
              root: {
                slotProps: {
                  backdrop: { className: 'timezone-select__backdrop' },
                  root: { className: 'timezone-select__popover' }
                }
              }
            }
          }}
        >
          {timezoneOptions.map(({ displayName, abbreviation, time, ...timezoneValueProps }) => (
            <MenuItem
              {...timezoneValueProps}
              key={timezoneValueProps.value as string}
              className="timezone-select__menu-item"
              data-testid={`timezone-select-item-${timezoneValueProps.value}`}
            >
              <div key={`${timezoneValueProps.value}-option`} className="timezone-select__option">
                <div className="timezone-select__tz-identifier">
                  <span className="timezone-select__display-name">{displayName}</span>
                  <span className="timezone-select__abbreviation">{abbreviation}</span>
                </div>
                <div>
                  <span className="timezone-select__time">{time}</span>
                </div>
              </div>
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

import { useApolloClient, useQuery, useSubscription } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { t } from 'i18next';
import { head, isEmpty } from 'lodash';
import { JSX, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { CustomerSitesList } from './customer-sites-list';
import { QUERY_GET_CUSTOMER_DETAILS, QUERY_GET_USER_AUTH_INFO } from '../../../services/queries';
import { SUBSCRIPTION_CUSTOMER_DETAILS } from '../../../services/subscriptions';
import { getPerformanceMetricsValues } from '../../../utilities';
import { DetailsMainSectionWrapper } from '../../2-templates';
import { PerformanceMetricsRender } from '../../4-features';
import { SubscriptionModeContext } from '../../contexts';
import { CustomerDetailsSidePanel } from '../customer-details-page';
import { ErrorPage } from '../error-page';
import { PageNotFoundErrorPage } from '../error-page/page-not-found-error-page';
import { LoadingPage } from '../loading-page';

export const CustomerDetailsPage = (): JSX.Element => {
  const { customerId } = useParams();
  const { user } = useAuth0();
  const apolloClient = useApolloClient();
  const { subscriptionMode } = useContext(SubscriptionModeContext);
  const {
    data: dataUser,
    loading: loadingUser,
    error: errorUser
  } = useQuery(QUERY_GET_USER_AUTH_INFO, { variables: { userAuthId: user?.sub || '' }, skip: !user?.sub });
  const queryVariables = { companyId: customerId! };
  const { loading, data, error } = useQuery(QUERY_GET_CUSTOMER_DETAILS, {
    variables: queryVariables,
    skip: subscriptionMode === true
  });
  const {
    loading: loadingSubscription,
    data: dataSubscription,
    error: errorSubscription
  } = useSubscription(SUBSCRIPTION_CUSTOMER_DETAILS, { variables: queryVariables, skip: subscriptionMode === false });

  const groupedError = error || errorUser || errorSubscription;

  if (groupedError) {
    const isUUIDError =
      groupedError.name === 'ApolloError' && groupedError.message.includes('invalid input syntax for type uuid');
    if (isUUIDError) {
      return <PageNotFoundErrorPage />;
    }

    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={groupedError.message}
      />
    );
  }

  const cachedData = apolloClient.readQuery({ query: QUERY_GET_CUSTOMER_DETAILS, variables: queryVariables });
  const generalLoadingParameters = loadingUser || !dataUser;
  const isLoading = subscriptionMode ? generalLoadingParameters : generalLoadingParameters || loading || !data;

  if (isLoading) {
    if ((subscriptionMode && !cachedData && loadingSubscription) || !subscriptionMode) {
      return <LoadingPage />;
    }
  }

  const customer = data?.customerByPK || dataSubscription?.customerByPK || cachedData?.customerByPK;

  if (isEmpty(customer)) {
    return <PageNotFoundErrorPage />;
  }

  // Only show "Back to customer" if: either the user is a super user, or the company type is NOT customer
  const showBackToCustomerOverview = head(dataUser!.users)!.isSuperUser || !head(dataUser!.users)!.company.customer;

  const customerPerformance = customer.customerCurrentPerformance;
  const performanceValues = getPerformanceMetricsValues(
    customerPerformance?.successLastPeriod || null,
    customerPerformance?.totalLastPeriod || null,
    customerPerformance?.successPrevPeriod || null,
    customerPerformance?.totalPrevPeriod || null
  );

  return (
    <DetailsMainSectionWrapper
      panel={
        <CustomerDetailsSidePanel
          title={customer.company.name}
          counts={{
            devices: customer.devicesAggregate.aggregate?.count || 0,
            sites: customer.sitesAggregate.aggregate?.count || 0,
            programs: customer.programs.length
          }}
          createdAt={customer.company.createdAt}
          updatedAt={customer.company.updatedAt}
          programs={customer.programs}
          showBackToCustomerOverview={showBackToCustomerOverview}
        />
      }
    >
      <section className="customer-details-page" data-testid="customer-details-page">
        <div className="customer-details-page__overview">
          <h2>{t('customerDetailsPage.sites.title')}</h2>
          <PerformanceMetricsRender
            acdCycleOperationsSuccessRate={performanceValues.acdCycleOperationsSuccessRate}
            acdCycleOperationsSuccessRateChange={performanceValues.acdCycleOperationsSuccessRateChange}
            acdCycleOperationsCount={performanceValues.acdCycleOperationsCount}
            acdCycleOperationsCountChange={performanceValues.acdCycleOperationsCountChange}
            loadingTotalAcdCyclesOperationResult={isLoading}
            errorTotalAcdCyclesOperationResult={subscriptionMode ? Boolean(errorSubscription) : Boolean(error)}
          />
        </div>
        <div className="customer-details-page__sites">
          <CustomerSitesList
            sites={customer.sites}
            loading={isLoading}
            hasError={subscriptionMode ? Boolean(errorSubscription) : Boolean(error)}
          />
        </div>
      </section>
    </DetailsMainSectionWrapper>
  );
};

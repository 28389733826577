import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { head } from 'lodash';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { MainNavMenuButton, MainNavMenuButtonProps } from './main-nav-menu-button';
import AcdCycleIcon from '../../../assets/icons/acd-cycle.svg?react';
import CustomerIcon from '../../../assets/icons/customer.svg?react';
import GearIcon from '../../../assets/icons/gear.svg?react';
import RocIcon from '../../../assets/icons/roc.svg?react';
import { appConfig } from '../../../configs';
import { SHOW_ADMIN_NAV_ITEM } from '../../../constants';
import { QUERY_GET_USER_AUTH_INFO } from '../../../services/queries';
import { getEnvironmentValue } from '../../../utilities';

export const MainNavMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const {
    data: dataUser,
    loading: loadingUser,
    error: errorUser
  } = useQuery(QUERY_GET_USER_AUTH_INFO, { variables: { userAuthId: user?.sub || '' }, skip: !user?.sub });

  if (errorUser || !dataUser || loadingUser) {
    return <nav className="main-nav-menu" data-testid="portal-main-nav-menu" />;
  }

  const showCustomers = head(dataUser.users)!.isSuperUser || !head(dataUser.users)!.company.customer;
  const customersButtonKey = showCustomers ? 'mainNavMenu.customers' : 'mainNavMenu.home';

  const mainNavMenuConfig: MainNavMenuButtonProps[] = [
    { label: t(customersButtonKey), icon: <CustomerIcon />, to: '/portal/customers' },
    { label: t('mainNavMenu.devices'), icon: <RocIcon />, to: '/portal/devices' },
    { label: t('mainNavMenu.operations'), icon: <AcdCycleIcon />, to: '/portal/operations' }
  ];

  const showAdminNavItem = getEnvironmentValue(SHOW_ADMIN_NAV_ITEM, appConfig.hostName);

  return (
    <nav className="main-nav-menu" data-testid="portal-main-nav-menu">
      <div className="main-nav-menu__portal">
        {mainNavMenuConfig.map((menuItem) => (
          <MainNavMenuButton key={menuItem.label} {...menuItem} />
        ))}
      </div>
      {showAdminNavItem && (
        <div className="main-nav-menu__admin">
          {/*
          To avoid the blinking effect introduced by react-router redirect, we redirect the user to the "landing page"
          of the admin interface, instead of `/portal/admin`. In the button component, the "active" class has been
          adjusted for the admin case.
        */}
          <MainNavMenuButton
            label={t('mainNavMenu.admin')}
            icon={<GearIcon />}
            to="/portal/admin/companies"
            isAdmin={true}
          />
        </div>
      )}
    </nav>
  );
};

import { InputLabel } from '@mui/material';
import { JSX, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RSSwitch } from '../../5-elements';
import { SubscriptionModeContext } from '../../contexts';

export const AutoRefreshSwitch = (): JSX.Element => {
  const { t } = useTranslation();
  const { subscriptionMode, setSubscriptionMode } = useContext(SubscriptionModeContext);

  return (
    <div className="auto-refresh-switch" data-testid="auto-refresh-switch">
      <InputLabel
        shrink={true}
        className="auto-refresh-switch__input-label"
        data-testid="auto-refresh-switch-input-label"
      >
        {t('autoRefreshSwitch.label')}
      </InputLabel>
      <RSSwitch
        data-testid="auto-refresh-switch-button"
        falseValueLabel={t('autoRefreshSwitch.off')}
        trueValueLabel={t('autoRefreshSwitch.on')}
        checked={subscriptionMode}
        onChange={(event) => setSubscriptionMode(event.target.checked)}
      />
    </div>
  );
};

import classNames from 'classnames';
import { includes } from 'lodash';
import { JSX } from 'react';
import { useLocation } from 'react-router-dom';

import { RSNavLinkTimezone, RSNavLinkTimezoneProps } from '..';

export interface MainNavMenuButtonProps extends RSNavLinkTimezoneProps {
  label: string;
  icon: JSX.Element;
  isAdmin?: boolean;
}

export const MainNavMenuButton = ({ label, icon, isAdmin, ...props }: MainNavMenuButtonProps): JSX.Element => {
  const routerLocation = useLocation();
  const menuButtonClassNames = (isActive: boolean, isAdmin?: boolean): string => {
    return classNames({
      'main-nav-menu-button': true,
      // Avoid blinking effect introduced by redirect
      'main-nav-menu-button--active':
        isActive === true || (isAdmin && includes(routerLocation.pathname, 'portal/admin') === true),
      'main-nav-menu-button--admin': isAdmin === true
    });
  };

  return (
    <RSNavLinkTimezone
      data-testid="main-nav-menu-button"
      {...props}
      className={({ isActive }) => menuButtonClassNames(isActive, isAdmin)}
    >
      <div className="main-nav-menu-button__icon-container">{icon}</div>
      <p className="main-nav-menu-button__label">{label}</p>
    </RSNavLinkTimezone>
  );
};

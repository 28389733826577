import { OrderBy, UserOrderBy } from '../../../../__generated__/graphql';
import { DEFAULT_GET_ADMIN_USERS_SORT_BY } from '../../../../constants';

export enum UsersAdminSortOptions {
  NameAsc = 'name',
  NameDesc = '-name',
  EmailAsc = 'email',
  EmailDesc = '-email',
  CompanyAsc = 'company',
  CompanyDesc = '-company',
  CompanyTypeAsc = 'companyType',
  CompanyTypeDesc = '-companyType',
  UserTypeAsc = 'userType',
  UserTypeDesc = '-userType',
  SuperUserAsc = 'superUser',
  SuperUserDesc = '-superUser',
  CreatedAtAsc = 'createdAt',
  CreatedAtDesc = '-createdAt',
  UpdatedAtAsc = 'updatedAt',
  UpdatedAtDesc = '-updatedAt'
}

export const generateSortQuery = (sortOption?: UsersAdminSortOptions): UserOrderBy[] => {
  switch (sortOption) {
    case UsersAdminSortOptions.NameDesc:
      return [{ firstName: OrderBy.Desc }, { lastName: OrderBy.Desc }];
    case UsersAdminSortOptions.EmailAsc:
      return [{ email: OrderBy.Asc }];
    case UsersAdminSortOptions.EmailDesc:
      return [{ email: OrderBy.Desc }];
    case UsersAdminSortOptions.CompanyAsc:
      return [{ company: { name: OrderBy.Asc } }, ...DEFAULT_GET_ADMIN_USERS_SORT_BY];
    case UsersAdminSortOptions.CompanyDesc:
      return [{ company: { name: OrderBy.Desc } }, ...DEFAULT_GET_ADMIN_USERS_SORT_BY];
    case UsersAdminSortOptions.CompanyTypeAsc:
      return [{ company: { companyType: OrderBy.Asc } }, ...DEFAULT_GET_ADMIN_USERS_SORT_BY];
    case UsersAdminSortOptions.CompanyTypeDesc:
      return [{ company: { companyType: OrderBy.Desc } }, ...DEFAULT_GET_ADMIN_USERS_SORT_BY];
    case UsersAdminSortOptions.UserTypeAsc:
      return [{ userType: OrderBy.Asc }, ...DEFAULT_GET_ADMIN_USERS_SORT_BY];
    case UsersAdminSortOptions.UserTypeDesc:
      return [{ userType: OrderBy.Desc }, ...DEFAULT_GET_ADMIN_USERS_SORT_BY];
    case UsersAdminSortOptions.SuperUserAsc:
      return [{ isSuperUser: OrderBy.DescNullsLast }, ...DEFAULT_GET_ADMIN_USERS_SORT_BY];
    case UsersAdminSortOptions.SuperUserDesc:
      return [{ isSuperUser: OrderBy.AscNullsFirst }, ...DEFAULT_GET_ADMIN_USERS_SORT_BY];
    case UsersAdminSortOptions.CreatedAtAsc:
      return [{ createdAt: OrderBy.Asc }, ...DEFAULT_GET_ADMIN_USERS_SORT_BY];
    case UsersAdminSortOptions.CreatedAtDesc:
      return [{ createdAt: OrderBy.Desc }, ...DEFAULT_GET_ADMIN_USERS_SORT_BY];
    case UsersAdminSortOptions.UpdatedAtAsc:
      return [{ updatedAt: OrderBy.Asc }, ...DEFAULT_GET_ADMIN_USERS_SORT_BY];
    case UsersAdminSortOptions.UpdatedAtDesc:
      return [{ updatedAt: OrderBy.Desc }, ...DEFAULT_GET_ADMIN_USERS_SORT_BY];
    case UsersAdminSortOptions.NameAsc:
    default:
      return DEFAULT_GET_ADMIN_USERS_SORT_BY;
  }
};

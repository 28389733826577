import { ACDCycleOperationResultSuccessRates } from '../../types';
import { calculateACDCycleOperationsResultSuccessRates } from '../calculate-acdcycle-operation-result-success-rates';
import { execIfBothDefined } from '../exec-if-both-defined';

export const getPerformanceMetricsValues = (
  successLastPeriod: number | null,
  totalLastPeriod: number | null,
  successPrevPeriod: number | null,
  totalPrevPeriod: number | null
): ACDCycleOperationResultSuccessRates => {
  return calculateACDCycleOperationsResultSuccessRates(
    successLastPeriod,
    execIfBothDefined(totalLastPeriod, successLastPeriod, (val1, val2) => val1 - val2),
    successPrevPeriod || null,
    execIfBothDefined(totalPrevPeriod, successPrevPeriod, (val1, val2) => val1 - val2)
  );
};

import { GetAdminCompaniesQuery } from '../../../../__generated__/graphql';
import i18n from '../../../../i18n';
import { FlattenFunction } from '../../../../types';
import { formatTimestamp } from '../../../../utilities';

export const generateCompaniesAdminRows = (companies?: GetAdminCompaniesQuery['companies'], userTimezone?: string) => {
  if (!companies) {
    return [];
  }

  return companies.map((company) => {
    return {
      id: company.id,
      name: company.name,
      type: company.companyType,
      sites:
        company.companyType === 'SERVICE_PROVIDER'
          ? i18n.t('noData')
          : company.customer?.sitesAggregate.aggregate?.count || 0,
      devices:
        company.companyType === 'SERVICE_PROVIDER'
          ? i18n.t('noData')
          : company.customer?.devicesAggregate.aggregate?.count || 0,
      users: company.usersAggregate.aggregate?.count || 0,
      allowImport: company.customer?.allowImport,
      createdAt: formatTimestamp(company.createdAt, userTimezone),
      updatedAt: formatTimestamp(company.updatedAt, userTimezone)
    };
  });
};

export type CompaniesAdminRow = FlattenFunction<typeof generateCompaniesAdminRows>;

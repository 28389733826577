import i18n from '../../i18n';
import { CompanyType } from '../../types';

export const mapCompanyType = (companyType: CompanyType): string => {
  switch (companyType) {
    case CompanyType.Customer:
      return i18n.t('displayCompanyType.customer');
    case CompanyType.ServiceProvider:
      return i18n.t('displayCompanyType.serviceProvider');
    default:
      return i18n.t('noData');
  }
};

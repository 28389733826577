import { gql } from '../../../../__generated__/gql';

export const QUERY_GET_USER_NAMES = gql(`
  query getUserNames {
    users(orderBy: [{ firstName: ASC }, { lastName: ASC }]) {
      id
      firstName
      lastName
    }
  }
`);

import { gql } from '../../../__generated__/gql';

export const QUERY_GET_CUSTOMERS = gql(`
  query getCustomers($offset: Int, $limit: Int, $filters: CustomerBoolExp, $orderBy: [CustomerOrderBy!]) {
    customers(orderBy: $orderBy, offset: $offset, limit: $limit, where: $filters) {
      companyId
      company {
        id
        name
      }
      devicesAggregate(where: { deactivated: { _eq: false } }) {
        aggregate {
          count
        }
      }
      sitesAggregate {
        aggregate {
          count
        }
      }
      programsAggregate {
        aggregate {
          count
        }
      }
      customerCurrentPerformance {
        customerId
        performanceDiff
        performanceLastPeriodPct
        totalDiffPct
        totalLastPeriod
      }
    }
    customersAggregate(where: $filters) {
      aggregate {
        count
      }
    }
  }
`);

import { GRAPHQL_ENDPOINT_URL, GRAPHQL_ENDPOINT_WS, PORTAL_AUDIENCE, PORTAL_CLIENT_ID } from '../constants';
import { getEnvironmentValue } from '../utilities/get-environment-value';

export const appConfig = {
  hostName: window.location.hostname,
  baseUrl: window.location.origin,
  basePath: '/portal',
  callbackUrl: '/portal/callback',
  muiLicenseKey: '409bf3bb128093e994dc0eaa5a7314ceTz05MzU0NSxFPTE3NTE1MzQyOTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
};

export const auth0Config = {
  clientId: getEnvironmentValue(PORTAL_CLIENT_ID, appConfig.hostName),
  audience: getEnvironmentValue(PORTAL_AUDIENCE, appConfig.hostName),
  domain: 'login.rocsys.com',
  redirectUri: `${appConfig.baseUrl}${appConfig.callbackUrl}` // portal.rocsys.com/portal/callback
};

export const graphqlApiConfig = {
  host: getEnvironmentValue(GRAPHQL_ENDPOINT_URL, appConfig.hostName),
  wsHost: getEnvironmentValue(GRAPHQL_ENDPOINT_WS, appConfig.hostName),
  graphqlEndpoint: '/v1/graphql',
  defaultTimeout: 20000,
  mutationTimeout: 20000
};

import { gql } from '../../../../__generated__/gql';

export const QUERY_GET_ADMIN_DEVICES = gql(`
  query getAdminDevices($offset: Int, $limit: Int, $filters: DeviceBoolExp, $orderBy: [DeviceOrderBy!]) {
    devicesAggregate(where: $filters) {
      aggregate {
        count
      }
    }
    devices(orderBy: $orderBy, offset: $offset, limit: $limit, where: $filters) {
      id
      serialNumber
      operationalLifeCycle
      connectorHolderType {
        id
        code
      }
      deviceType {
        id
        modelNumber
      }
      site {
        id
        name
        customer {
          companyId
          company {
            id
            name
          }
        }
      }
      program {
        id
        name
      }
      deviceGroupDevicesAggregate {
        aggregate {
          count
        }
      }
      createdAt
      updatedAt
    }
  }
`);

import { zodResolver } from '@hookform/resolvers/zod';
import { filter, includes, isEqual, omit, pick } from 'lodash';
import qs from 'qs';
import { JSX, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { GetCompanyNamesQuery } from '../../../../__generated__/graphql';
import { CompanyType, DeviceAllowImport, RSAutocompleteValue } from '../../../../types';
import { filterValidUrlFields, mapCompanyType, mapDeviceAllowImportDisplayLabel } from '../../../../utilities';
import { FilterPanelButtonsGroup } from '../../../4-features';
import { RSAutocomplete, RSAutocompleteDefaultMenuOption } from '../../../5-elements';
import { CompaniesAdminSearchParameters, companiesAdminStatesSchema } from '../companies-admin-states-schema';
import { companiesAdminFilterFields } from '../generate-queries';

interface CompaniesAdminFilterPanelProps {
  companyNames?: GetCompanyNamesQuery;
  isLoading?: boolean;
  defaultValues: CompaniesAdminSearchParameters;
}

export const CompaniesAdminFilterPanel = ({
  companyNames,
  isLoading,
  defaultValues
}: CompaniesAdminFilterPanelProps): JSX.Element => {
  const { t } = useTranslation();
  const routerLocation = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
    getValues
  } = useForm<CompaniesAdminSearchParameters>({
    resolver: zodResolver(companiesAdminStatesSchema),
    defaultValues
  });
  const nameOptions = (companyNames?.companies.map((company) => company.name) as string[]) || [];
  const typeOptions = Object.values(CompanyType);
  const allowImportOptions = Object.values(DeviceAllowImport);

  const typeMenuDisplayOptions = (option: RSAutocompleteValue) => {
    return <RSAutocompleteDefaultMenuOption option={mapCompanyType(option as CompanyType)} />;
  };
  const typeTagDisplayOptions = (option: RSAutocompleteValue) => mapCompanyType(option as CompanyType);
  const allowImportMenuDisplayOptions = (option: RSAutocompleteValue) => {
    return <RSAutocompleteDefaultMenuOption option={mapDeviceAllowImportDisplayLabel(option as DeviceAllowImport)} />;
  };
  const allowImportTagDisplayOptions = (option: RSAutocompleteValue) =>
    mapDeviceAllowImportDisplayLabel(option as DeviceAllowImport);

  const onSubmit: SubmitHandler<CompaniesAdminSearchParameters> = (data): void => {
    const newSearchParams = { ...qs.parse(searchParams.toString()), ...data, page: '1' };
    setSearchParams(new URLSearchParams(qs.stringify(newSearchParams, { arrayFormat: 'brackets' })));
    reset(data);
  };

  const onReset: SubmitHandler<CompaniesAdminSearchParameters> = (): void => {
    const searchParamsObject = qs.parse(searchParams.toString());
    const searchParamsObjectNoFilter = omit(searchParamsObject, companiesAdminFilterFields);
    searchParamsObjectNoFilter.page = '1';
    setSearchParams(new URLSearchParams(qs.stringify(searchParamsObjectNoFilter, { arrayFormat: 'brackets' })));
    reset({ name: [], type: [], allowImport: [] });
  };

  useEffect(() => {
    const searchParameters = qs.parse(searchParams.toString());

    const validUrlFields = filterValidUrlFields<CompaniesAdminSearchParameters>(
      searchParameters,
      companiesAdminStatesSchema
    );

    const filterParameters = pick(validUrlFields, companiesAdminFilterFields);
    const resetObject: CompaniesAdminSearchParameters = {
      name: filterParameters.name || [],
      type: filterParameters.type || [],
      allowImport: filterParameters.allowImport || []
    };
    if (!isEqual(getValues(), resetObject)) {
      reset(resetObject);
    }
  }, [routerLocation.search]);

  return (
    <aside className="companies-admin-filter-panel" data-testid="companies-admin-filter-panel">
      <form className="companies-admin-filter-panel__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="companies-admin-filter-panel__filters">
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('companiesAdminPage.filterPanel.name')}
                  data-testid="companies-admin-name-autocomplete"
                  options={nameOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(nameOptions, valueItem))}
                  onBlur={onBlur}
                  disabled={isLoading}
                />
              );
            }}
          />
          <Controller
            name="type"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('companiesAdminPage.filterPanel.type')}
                  data-testid="companies-admin-type-autocomplete"
                  options={typeOptions}
                  customMenuOption={typeMenuDisplayOptions}
                  customTagOption={typeTagDisplayOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(typeOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="allowImport"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('companiesAdminPage.filterPanel.allowImport')}
                  data-testid="companies-admin-allow-import-autocomplete"
                  options={allowImportOptions}
                  customMenuOption={allowImportMenuDisplayOptions}
                  customTagOption={allowImportTagDisplayOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(allowImportOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
        </div>
        <FilterPanelButtonsGroup
          handleApply={handleSubmit(onSubmit)}
          handleClearAll={handleSubmit(onReset)}
          isApplyDisabled={!isDirty}
          variant="admin"
        />
      </form>
    </aside>
  );
};

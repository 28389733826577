import { gql } from '../../../__generated__/gql';

export const QUERY_GET_CUSTOMER_CURRENT_PERFORMANCE_AGGREGATE = gql(`
  query getCustomerCurrentPerformanceAggregate($filters: CustomerBoolExp) {
    customerCurrentPerformanceAggregate(where: { customer: $filters }) {
      aggregate {
        sum {
          totalLastPeriod
          totalPrevPeriod
          successPrevPeriod
          successLastPeriod
        }
      }
    }
  }
`);

import { toString } from 'lodash';
import qs from 'qs';
import { JSX, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { appConfig } from '../../../configs';
import { RSButtonLink } from '../../5-elements';
import { UserTimezoneContext } from '../../contexts';

interface ErrorPageProps {
  titleEmphasized: string | number;
  title: string;
  name?: string;
  message?: string;
  buttonText?: string;
  buttonLink?: string;
  hideButtonLink?: boolean;
}

export const ErrorPage = ({
  titleEmphasized,
  title,
  name,
  message,
  buttonText,
  buttonLink,
  hideButtonLink
}: ErrorPageProps): JSX.Element => {
  const { t } = useTranslation();
  const { userTimezone } = useContext(UserTimezoneContext);
  return (
    <section className="error-page" data-testid="error-page">
      <div className="error-page__box">
        <div className="error-page__box-content">
          <div className="error-page__box-title">
            <h1>
              <span className="error-page__box-emphasize-title">{`${toString(titleEmphasized)}.`}</span>&nbsp;
              {title}
            </h1>
          </div>
          <div className="error-page__box-texts">
            {name && <p className="error-page__box-name">{name}</p>}
            <p className="error-page__box-message">{message}</p>
          </div>
        </div>
        {!hideButtonLink && (
          <RSButtonLink
            variant="contained"
            to={buttonLink || `${appConfig.basePath}?${qs.stringify({ timezone: userTimezone })}`}
            data-testid="error-page-button"
          >
            {buttonText || t('errorPage.goBackButtonText')}
          </RSButtonLink>
        )}
      </div>
    </section>
  );
};

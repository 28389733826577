import { JSX, ReactNode } from 'react';

interface AdminPageTitleProps {
  titleName: string;
  actions?: ReactNode;
}

export const AdminPageTitle = ({ titleName, actions }: AdminPageTitleProps): JSX.Element => {
  return (
    <div className="admin-page-title" data-testid="admin-page-title">
      <h2 className="admin-page-title__name" data-testid="admin-page-title-name">
        {titleName}
      </h2>
      {actions && (
        <div className="admin-page-title__actions" data-testid="admin-page-title-actions">
          {actions}
        </div>
      )}
    </div>
  );
};

import { JSX } from 'react';

import { GetCustomerDetailsQuery } from '../../../../__generated__/graphql';
import { ACDCycleOperationResultSuccessRates, Flatten } from '../../../../types';
import { getPerformanceMetricsValues } from '../../../../utilities';
import { SiteCard } from '../../../4-features';

type CustomerSites = NonNullable<GetCustomerDetailsQuery['customerByPK']>['sites'];
type CustomerSite = Flatten<CustomerSites>;
interface CustomerSitesListProps {
  sites: CustomerSites;
  loading?: boolean;
  hasError?: boolean;
}

export const CustomerSitesList = ({ sites }: CustomerSitesListProps): JSX.Element => {
  const getSitePrograms = (site: CustomerSite): string[] => {
    const programs = site.devices.map((device) => device.program?.name);
    return programs.filter((program) => Boolean(program) === true) as string[];
  };

  const getSitePerformance = (site: CustomerSite): ACDCycleOperationResultSuccessRates => {
    const currentPerformance = site.siteCurrentPerformance;
    return getPerformanceMetricsValues(
      currentPerformance?.successLastPeriod || null,
      currentPerformance?.totalLastPeriod || null,
      currentPerformance?.successPrevPeriod || null,
      currentPerformance?.totalPrevPeriod || null
    );
  };

  return (
    <div className="customer-sites-list">
      {sites.map((site) => (
        <SiteCard
          key={site.id}
          id={site.id}
          name={site.name}
          devicesCount={site.devicesAggregate.aggregate?.count}
          programs={getSitePrograms(site)}
          address={site.location.formattedAddress}
          latitude={site.location.latitude}
          longitude={site.location.longitude}
          performance={getSitePerformance(site)}
        />
      ))}
    </div>
  );
};

import { GridColDef } from '@mui/x-data-grid';

import { CustomerProgramRow } from './generate-customer-program-rows';
import i18n from '../../../../../i18n';
import { ACDCycleOperationResultSuccessRates } from '../../../../../types';
import { DataGridHeaderCell, DataGridPerformanceMetricCell } from '../../../../5-elements';

export const customerProgramsTableColumns: GridColDef<CustomerProgramRow>[] = [
  {
    field: 'name',
    hideable: false,
    sortable: false,
    width: 120,
    headerName: i18n.t('customerDetailsPage.sidePanel.programsTable.name'),
    headerClassName: 'customer-programs-table__program-header',
    cellClassName: 'customer-programs-table__program-cell',
    renderHeader: ({ colDef }) => (
      <DataGridHeaderCell title={colDef.headerName!} sortable={false} field={colDef.field} />
    )
  },
  {
    field: 'performance',
    hideable: false,
    sortable: false,
    width: 160,
    headerClassName: 'customer-programs-table__performance-header',
    cellClassName: 'customer-programs-table__performance-cell',
    headerName: i18n.t('customerDetailsPage.sidePanel.programsTable.performance'),
    renderHeader: ({ colDef }) => {
      return <DataGridHeaderCell title={colDef.headerName!} sortable={false} field={colDef.field} />;
    },
    renderCell: ({ value }) => {
      const operationalPerformanceValue = value as ACDCycleOperationResultSuccessRates;
      return (
        <DataGridPerformanceMetricCell
          metric={{
            value: operationalPerformanceValue.acdCycleOperationsSuccessRate,
            displayUnit: i18n.t('units.percentage')
          }}
          metricChange={{
            value: operationalPerformanceValue.acdCycleOperationsSuccessRateChange,
            displayUnit: i18n.t('units.percentage')
          }}
          metricCount={{
            value: operationalPerformanceValue.acdCycleOperationsCount,
            displayUnit: i18n.t('dataGrid.operationalPerformance.units.metricCount')
          }}
        />
      );
    }
  },
  {
    field: 'rocs',
    hideable: false,
    sortable: false,
    width: 70,
    headerName: i18n.t('customerDetailsPage.sidePanel.programsTable.devices'),
    align: 'right',
    headerAlign: 'right',
    headerClassName: 'customer-programs-table__rocs-header',
    cellClassName: 'customer-programs-table__rocs-cell',
    renderHeader: ({ colDef }) => {
      return <DataGridHeaderCell title={colDef.headerName!} sortable={false} field={colDef.field} />;
    }
  }
];

import { isEmpty, uniq } from 'lodash';

import { CompanyBoolExp } from '../../../../__generated__/graphql';
import { mapDeviceAllowImport } from '../../../../utilities';
import { CompaniesAdminSearchParameters } from '../companies-admin-states-schema';

export const companiesAdminFilterFields = ['name', 'type', 'allowImport'] as const;

type CompaniesAdminFilterField = (typeof companiesAdminFilterFields)[number];

export const generateFilterQuery = (
  filterParameters: Pick<CompaniesAdminSearchParameters, CompaniesAdminFilterField>
): CompanyBoolExp => {
  const filterQuery: CompanyBoolExp = { _and: [] };
  if (filterParameters.name) {
    filterQuery._and!.push({ name: { _in: uniq(filterParameters.name) } });
  }
  if (filterParameters.type) {
    filterQuery._and!.push({ companyType: { _in: uniq(filterParameters.type) } });
  }
  if (filterParameters.allowImport) {
    const uniqueAllowImportFilterValue = uniq(filterParameters.allowImport);
    if (uniqueAllowImportFilterValue.length === 1) {
      filterQuery._and!.push({
        customer: { allowImport: { _eq: mapDeviceAllowImport(uniqueAllowImportFilterValue[0]) } }
      });
    }
  }

  if (isEmpty(filterQuery._and)) {
    return {};
  }
  return filterQuery;
};

import { GridColDef } from '@mui/x-data-grid';

import { UsersAdminRow } from './generate-users-admin-rows';
import i18n from '../../../../i18n';
import { BooleanCheckmarkCross, DataGridHeaderCell } from '../../../5-elements';

export const usersAdminDataGridColumns: GridColDef<UsersAdminRow>[] = [
  {
    field: 'name',
    hideable: false,
    sortable: false,
    flex: 1.7,
    minWidth: 170,
    headerName: i18n.t('usersAdminPage.tableColumns.name'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />
  },
  {
    field: 'email',
    hideable: false,
    sortable: false,
    flex: 2,
    minWidth: 200,
    headerName: i18n.t('usersAdminPage.tableColumns.email'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />
  },
  {
    field: 'company',
    hideable: false,
    sortable: false,
    flex: 2.3,
    minWidth: 230,
    headerName: i18n.t('usersAdminPage.tableColumns.company'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />
  },
  {
    field: 'companyType',
    hideable: false,
    sortable: false,
    flex: 1.5,
    minWidth: 150,
    headerName: i18n.t('usersAdminPage.tableColumns.companyType'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />
  },
  {
    field: 'userType',
    hideable: false,
    sortable: false,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('usersAdminPage.tableColumns.userType'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />
  },
  {
    field: 'deviceGroups',
    hideable: false,
    sortable: false,
    flex: 1.2,
    minWidth: 120,
    headerName: i18n.t('usersAdminPage.tableColumns.deviceGroups'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} />
  },
  {
    field: 'superUser',
    hideable: false,
    sortable: false,
    flex: 1.2,
    minWidth: 120,
    headerName: i18n.t('usersAdminPage.tableColumns.superUser'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />,
    renderCell: ({ value }) => {
      const superUser = value as boolean;
      return <BooleanCheckmarkCross extraClassNames={['users-admin-page__super-user']} value={superUser} />;
    }
  },
  {
    field: 'permissions',
    hideable: false,
    sortable: false,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('usersAdminPage.tableColumns.permissions'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} />
  },
  {
    field: 'createdAt',
    hideable: false,
    sortable: false,
    flex: 1.7,
    minWidth: 170,
    headerName: i18n.t('companiesAdminPage.tableColumns.createdAt'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />
  },
  {
    field: 'updatedAt',
    hideable: false,
    sortable: false,
    flex: 1.7,
    minWidth: 170,
    headerName: i18n.t('usersAdminPage.tableColumns.updatedAt'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />
  }
];

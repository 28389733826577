import { JSX } from 'react';

import PlusSignIcon from '../../../../assets/icons/plus-sign.svg?react';
import { RSButton, RSButtonProps } from '../../../5-elements';

interface AdminAddActionButtonProps extends Omit<RSButtonProps, 'children'> {
  titleName: string;
}

export const AdminAddActionButton = ({ titleName, ...props }: AdminAddActionButtonProps): JSX.Element => {
  const buttonClassNames = props.extraClassNames
    ? ['admin-add-action-button', ...props.extraClassNames]
    : ['admin-add-action-button'];
  return (
    <RSButton {...props} color="success" variant="contained" extraClassNames={buttonClassNames}>
      <div className="admin-add-action-button__icon-wrapper">
        <PlusSignIcon />
      </div>
      <span className="admin-add-action-button__title-name">{titleName}</span>
    </RSButton>
  );
};

import { BaseSubscriptionOptions, QueryHookOptions, useQuery, useSubscription } from '@apollo/client';
import { JSX, useContext } from 'react';

import {
  GetCustomerCurrentPerformanceAggregateQuery,
  SubscribeCustomerCurrentPerformanceAggregateSubscription
} from '../../../__generated__/graphql';
import { QUERY_GET_CUSTOMER_CURRENT_PERFORMANCE_AGGREGATE } from '../../../services/queries';
import { SUBSCRIPTION_CUSTOMER_CURRENT_PERFORMANCE_AGGREGATE } from '../../../services/subscriptions';
import { calculateACDCycleOperationsResultSuccessRates, execIfBothDefined } from '../../../utilities';
import { PerformanceMetricsRender } from '../../4-features';
import { SubscriptionModeContext } from '../../contexts';

interface PerformanceMetricsCustomerAggregateProps {
  queryHookOptions?: QueryHookOptions<GetCustomerCurrentPerformanceAggregateQuery>;
  subscriptionHookOptions?: BaseSubscriptionOptions<SubscribeCustomerCurrentPerformanceAggregateSubscription>;
}

export const PerformanceMetricsCustomerAggregate = ({
  queryHookOptions,
  subscriptionHookOptions
}: PerformanceMetricsCustomerAggregateProps): JSX.Element => {
  const { subscriptionMode } = useContext(SubscriptionModeContext);
  const { loading, data, error } = useQuery(QUERY_GET_CUSTOMER_CURRENT_PERFORMANCE_AGGREGATE, { ...queryHookOptions });
  const {
    loading: loadingSubscription,
    data: dataSubscription,
    error: errorSubscription
  } = useSubscription(SUBSCRIPTION_CUSTOMER_CURRENT_PERFORMANCE_AGGREGATE, { ...subscriptionHookOptions });

  const counts =
    (subscriptionMode
      ? dataSubscription?.customerCurrentPerformanceAggregate.aggregate?.sum
      : data?.customerCurrentPerformanceAggregate.aggregate?.sum) || {};

  const values = calculateACDCycleOperationsResultSuccessRates(
    counts?.successLastPeriod ?? null,
    execIfBothDefined(counts.totalLastPeriod, counts.successLastPeriod, (val1, val2) => val1 - val2),
    counts.successPrevPeriod ?? null,
    execIfBothDefined(counts.totalPrevPeriod, counts.successPrevPeriod, (val1, val2) => val1 - val2)
  );

  return (
    <PerformanceMetricsRender
      acdCycleOperationsSuccessRate={values.acdCycleOperationsSuccessRate}
      acdCycleOperationsSuccessRateChange={values.acdCycleOperationsSuccessRateChange}
      acdCycleOperationsCount={values.acdCycleOperationsCount}
      acdCycleOperationsCountChange={values.acdCycleOperationsCountChange}
      loadingTotalAcdCyclesOperationResult={subscriptionMode ? loadingSubscription : loading}
      errorTotalAcdCyclesOperationResult={subscriptionMode ? !!errorSubscription : !!error}
    />
  );
};

import { JSX, PropsWithChildren, useState } from 'react';

import { browserTimezone } from '../../../utilities';
import { SubscriptionModeContext, UserTimezoneContext } from '../../contexts';
import { AuthenticatedOnlyWrapper } from '../authenticated-only-wrapper';
import { HeaderNavigationWrapper } from '../header-navigation-wrapper';
import { HeaderOnlyWrapper } from '../header-only-wrapper';

interface PageWrapperProps extends PropsWithChildren {
  loginRequired: boolean;
  hasNavigation: boolean;
  loginRedirectUri: string;
}

export const PageWrapper = (props: PageWrapperProps): JSX.Element => {
  const [userTimezone, setUserTimezone] = useState<string>(browserTimezone);
  const [subscriptionMode, setSubscriptionMode] = useState<boolean>(false);
  const timezoneValue = { userTimezone, setUserTimezone };
  const subscriptionModeValue = { subscriptionMode, setSubscriptionMode };

  if (props.loginRequired) {
    if (props.hasNavigation) {
      return (
        <AuthenticatedOnlyWrapper loginRedirectUri={props.loginRedirectUri}>
          <UserTimezoneContext.Provider value={timezoneValue}>
            <SubscriptionModeContext.Provider value={subscriptionModeValue}>
              <HeaderNavigationWrapper>{props.children}</HeaderNavigationWrapper>
            </SubscriptionModeContext.Provider>
          </UserTimezoneContext.Provider>
        </AuthenticatedOnlyWrapper>
      );
    }

    return (
      <AuthenticatedOnlyWrapper loginRedirectUri={props.loginRedirectUri}>
        <UserTimezoneContext.Provider value={timezoneValue}>
          <SubscriptionModeContext.Provider value={subscriptionModeValue}>
            <HeaderOnlyWrapper>{props.children}</HeaderOnlyWrapper>
          </SubscriptionModeContext.Provider>
        </UserTimezoneContext.Provider>
      </AuthenticatedOnlyWrapper>
    );
  }

  if (props.hasNavigation) {
    return <HeaderNavigationWrapper>{props.children}</HeaderNavigationWrapper>;
  }

  return <HeaderOnlyWrapper>{props.children}</HeaderOnlyWrapper>;
};

import { gql } from '../../../__generated__/gql';

export const MUTATION_MODIFY_DEVICE_CLOUD_SETTINGS = gql(`
  mutation modifyDeviceCloudSettings(
    $deviceId: uuid!
    $autoImageCollectionEnabled: Boolean
    $autoLogCollectionEnabled: Boolean
    $autoLogCollectionOnSuccessEnabled: Boolean
    $notificationsEnabled: Boolean
    $notificationsOnFailedCycleEnabled: Boolean
    $rocsysApiEnabled: Boolean
    $deviceMeasurementsRetentionDays: Int
    $deviceOperationsRetentionDays: Int
    $vpnEnabled: Boolean
  ) {
    modifyDeviceCloudSettings(
      deviceId: $deviceId
      autoImageCollectionEnabled: $autoImageCollectionEnabled
      autoLogCollectionEnabled: $autoLogCollectionEnabled
      autoLogCollectionOnSuccessEnabled: $autoLogCollectionOnSuccessEnabled
      notificationsEnabled: $notificationsEnabled
      notificationsOnFailedCycleEnabled: $notificationsOnFailedCycleEnabled
      rocsysApiEnabled: $rocsysApiEnabled
      deviceMeasurementsRetentionDays: $deviceMeasurementsRetentionDays
      deviceOperationsRetentionDays: $deviceOperationsRetentionDays
      vpnEnabled: $vpnEnabled
    ) {
      deviceId
      deviceCloudSettings {
        autoImageCollectionEnabled
        autoLogCollectionEnabled
        autoLogCollectionOnSuccessEnabled
        id
        deviceOperationsRetentionDays
        deviceMeasurementsRetentionDays
        notificationsEnabled
        notificationsOnFailedCycleEnabled
        rocsysApiEnabled
        vpnEnabled
        deviceId
      }
    }
  }
`);

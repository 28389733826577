import { faker } from '@faker-js/faker';

import { GetCustomersQuery } from '../../../__generated__/graphql';

// Generate 90 items for pagination test
const fakerGeneratedCustomers = (): GetCustomersQuery['customers'] => {
  const customers: GetCustomersQuery['customers'] = [];
  for (let i = 0; i < 90; i += 1) {
    const companyId = faker.string.uuid();
    customers.push({
      companyId: companyId,
      company: {
        id: companyId,
        name: faker.company.name()
      },
      devicesAggregate: { aggregate: { count: faker.number.int({ min: 0, max: 10 }) } },
      sitesAggregate: { aggregate: { count: faker.number.int({ min: 0, max: 10 }) } },
      programsAggregate: { aggregate: { count: faker.number.int({ min: 0, max: 10 }) } },
      customerCurrentPerformance: {
        customerId: companyId,
        performanceDiff: faker.number.float({ min: -10, max: 10 }),
        performanceLastPeriodPct: faker.number.float({ min: 0, max: 100 }),
        totalDiffPct: faker.number.float({ min: -10, max: 10 }),
        totalLastPeriod: faker.number.int({ min: 50, max: 500 })
      }
    });
  }

  return customers;
};

export const fakerGeneratedCustomersResponse: GetCustomersQuery = {
  customersAggregate: { aggregate: { count: 90 } },
  customers: fakerGeneratedCustomers()
};

export const getCustomersResponse: GetCustomersQuery = {
  customers: [
    {
      companyId: '202c874c-8b55-4327-96ef-6cf78f106c52',
      company: {
        id: '202c874c-8b55-4327-96ef-6cf78f106c52',
        name: 'Good EV Comp'
      },
      devicesAggregate: { aggregate: { count: 95 } },
      sitesAggregate: { aggregate: { count: 1 } },
      programsAggregate: { aggregate: { count: 13 } },
      customerCurrentPerformance: {
        customerId: '202c874c-8b55-4327-96ef-6cf78f106c52',
        performanceDiff: -5.0,
        performanceLastPeriodPct: 90.0,
        totalDiffPct: 0.0,
        totalLastPeriod: 100
      }
    },
    {
      companyId: '65ba0cb2-3eb4-40a8-9cac-d151f9d4cbc5',
      company: {
        id: '65ba0cb2-3eb4-40a8-9cac-d151f9d4cbc5',
        name: 'Rocsys Production Factory'
      },
      devicesAggregate: { aggregate: { count: 3 } },
      sitesAggregate: { aggregate: { count: 3 } },
      programsAggregate: { aggregate: { count: 11 } },
      customerCurrentPerformance: {
        customerId: '202c874c-8b55-4327-96ef-6cf78f106c52',
        performanceDiff: -5.0,
        performanceLastPeriodPct: 90.0,
        totalDiffPct: 0.0,
        totalLastPeriod: 100
      }
    }
  ],
  customersAggregate: { aggregate: { count: 2 } }
};

import { GridColDef } from '@mui/x-data-grid';

import { DevicesAdminRow } from './generate-devices-admin-rows';
import i18n from '../../../../i18n';
import { DataGridHeaderCell } from '../../../5-elements';

export const devicesAdminDataGridColumns: GridColDef<DevicesAdminRow>[] = [
  {
    field: 'serialNumber',
    hideable: false,
    sortable: false,
    flex: 1.4,
    minWidth: 140,
    headerName: i18n.t('devicesAdminPage.tableColumns.serialNumber'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />
  },
  {
    field: 'company',
    hideable: false,
    sortable: false,
    flex: 1.4,
    minWidth: 140,
    headerName: i18n.t('devicesAdminPage.tableColumns.company'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />
  },
  {
    field: 'site',
    hideable: false,
    sortable: false,
    flex: 1.4,
    minWidth: 140,
    headerName: i18n.t('devicesAdminPage.tableColumns.site'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />
  },
  {
    field: 'program',
    hideable: false,
    sortable: false,
    flex: 1.0,
    minWidth: 100,
    headerName: i18n.t('devicesAdminPage.tableColumns.program'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />
  },
  {
    field: 'deviceGroups',
    hideable: false,
    sortable: false,
    flex: 1.0,
    minWidth: 100,
    headerName: i18n.t('devicesAdminPage.tableColumns.deviceGroups'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} />
  },
  {
    field: 'rocType',
    hideable: false,
    sortable: false,
    flex: 1.3,
    minWidth: 130,
    headerName: i18n.t('devicesAdminPage.tableColumns.rocType'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />
  },
  {
    field: 'connectorHolderType',
    hideable: false,
    sortable: false,
    flex: 1.3,
    minWidth: 130,
    headerName: i18n.t('devicesAdminPage.tableColumns.connectorHolderType'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />
  },
  {
    field: 'createdAt',
    hideable: false,
    sortable: false,
    flex: 1.3,
    minWidth: 130,
    headerName: i18n.t('devicesAdminPage.tableColumns.createdAt'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />
  },
  {
    field: 'updatedAt',
    hideable: false,
    sortable: false,
    flex: 1.3,
    minWidth: 130,
    headerName: i18n.t('devicesAdminPage.tableColumns.updatedAt'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />
  }
];

import { GridColDef } from '@mui/x-data-grid';

import { DeviceLatestOperationRow } from './generate-device-latest-operation-rows';
import { appConfig } from '../../../../configs';
import i18n from '../../../../i18n';
import { generateSequenceNumberString } from '../../../../utilities';
import { DataGridOperationResultCell, DataGridOperationResultCellProps } from '../../../4-features';
import { DataGridHeaderCell, DataGridLink } from '../../../5-elements';

export const deviceLatestOperationColumns: GridColDef<DeviceLatestOperationRow>[] = [
  {
    field: 'serialNumber',
    hideable: false,
    sortable: false,
    flex: 0.95,
    minWidth: 190,
    headerName: i18n.t('deviceDetailsPage.operations.tableColumns.serialNumber'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} />,
    renderCell: ({ row: { id, deviceId }, value }) => {
      return (
        <>
          {`${value.serialNumber} - `}
          <DataGridLink to={`${appConfig.basePath}/devices/${deviceId}?operationId=${id}`}>
            {generateSequenceNumberString(value.sequence)}
          </DataGridLink>
        </>
      );
    }
  },
  {
    field: 'result',
    hideable: false,
    sortable: false,
    flex: 1,
    minWidth: 200,
    headerName: i18n.t('deviceDetailsPage.operations.tableColumns.result'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} />,
    renderCell: ({ value }) => {
      const operationResultValue = value as DataGridOperationResultCellProps;
      return <DataGridOperationResultCell {...operationResultValue} />;
    }
  },
  {
    field: 'dateTime',
    hideable: false,
    sortable: false,
    flex: 0.9,
    minWidth: 180,
    headerName: i18n.t('deviceDetailsPage.operations.tableColumns.dateTime'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} />
  },
  {
    field: 'duration',
    hideable: false,
    sortable: false,
    flex: 0.45,
    minWidth: 90,
    headerName: i18n.t('deviceDetailsPage.operations.tableColumns.duration'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} />
  },
  {
    field: 'operationalLifeCycle',
    hideable: false,
    sortable: false,
    flex: 0.95,
    minWidth: 190,
    headerName: i18n.t('deviceDetailsPage.operations.tableColumns.operationalLifeCycle'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} />
  }
];

import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import VoiceChatOutlinedIcon from '@mui/icons-material/VoiceChatOutlined';
import { toString, toUpper } from 'lodash';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { dummyMovies } from '../../../__mocks__';
import ArrowLeftIcon from '../../../assets/icons/arrow-left.svg?react';
import CheckmarkIcon from '../../../assets/icons/check-mark.svg?react';
import ExternalLinkIcon from '../../../assets/icons/external-link.svg?react';
import ImageIcon from '../../../assets/icons/image.svg?react';
import LightIcon from '../../../assets/icons/light.svg?react';
import MetadataIcon from '../../../assets/icons/metadata.svg?react';
import { RSAutocompleteValue } from '../../../types';
import { ModalDrawerFooter, ModalDrawerHeader, PerformanceMetricBlock, UploadStatusChip } from '../../4-features';
import {
  CountBlock,
  ExternalLink,
  IconWithDescription,
  InformationBlock,
  InternalLink,
  RSActionButton,
  RSAutocomplete,
  RSButton,
  RSChip
} from '../../5-elements';

export const TestPage = (): JSX.Element => {
  const { t } = useTranslation();

  const testCustomMenuOption = (option: RSAutocompleteValue) => {
    return (
      <span className="test-page__dummy-menu-option">
        <LiveTvOutlinedIcon />
        &nbsp;{option}
      </span>
    );
  };
  const testCustomTagOption = (option: RSAutocompleteValue) => {
    return (
      <span className="test-page__dummy-tag-option">
        <VoiceChatOutlinedIcon fontSize="small" />
        &nbsp;{toUpper(toString(option))}
      </span>
    );
  };

  return (
    <div className="test-page">
      <h1>Components Demo 🤖</h1>
      <div>
        <h2 className="test-page__division-title">Performance metrics</h2>
        <p>
          The performance metrics is a <b>presentation</b> component, meaning that it displays the value it receives as
          it is. The decimal places handling is done by <b>independent utility functions</b>. In the example below, we
          show different combinations of both the presentation component and the utility function.
        </p>
        <br />
        <div className="test-page__performance-metrics">
          <PerformanceMetricBlock
            label="Default behaviour: decimal places 1 (the actual value is 2.7364 / -1.2345)"
            metric={{ value: 2.7364, displayUnit: '%' }}
            metricChange={{ value: -1.2345, displayUnit: '%' }}
          />
          <PerformanceMetricBlock
            label="Decimal places 3 (the actual value is 2.7364 / -1.2345)"
            metric={{ value: 2.7364, displayUnit: '%', decimalPlaces: 3 }}
            metricChange={{ value: -1.2345, displayUnit: '%', decimalPlaces: 3 }}
          />
          <PerformanceMetricBlock
            label="Metric: show 0 for 0; metric change: show change value 0"
            metric={{ value: 0, displayUnit: '%' }}
            metricChange={{ value: 0, displayUnit: '%' }}
          />
          <PerformanceMetricBlock
            label="Decimal place: 0, change value is null"
            metric={{ value: 3, displayUnit: ' capybaras', decimalPlaces: 0 }}
            metricChange={{ value: null }}
          />
        </div>
      </div>
      <div>
        <h2 className="test-page__division-title">Buttons</h2>
        <div className="test-page__buttons">
          <RSButton variant="contained">Default</RSButton>
          <RSButton variant="text" startIcon={<ExternalLinkIcon />}>
            Cancel
          </RSButton>
          <RSButton variant="contained" color="success">
            Confirm
          </RSButton>
          <RSButton variant="contained" color="warning">
            Warning
          </RSButton>
          <RSButton variant="contained" color="error">
            Error
          </RSButton>
        </div>
      </div>
      <div>
        <h2 className="test-page__division-title">Image overlay - header and bottom bar</h2>
        <div className="test-page__modal-header-footer-bar">
          <ModalDrawerHeader
            title="INITIAL-0-RAW"
            actions={
              <div>
                <RSActionButton
                  text={t('operationsPage.operationDetails.imageModal.actions.image')}
                  startIcon={<ImageIcon className="rs-action-button__start-icon" />}
                  iconContainerClassName="rs-action-button__icon-container__image"
                  data-testid="operation-details-image-modal-image-button"
                  onClick={() => alert('image action')}
                />
                <RSActionButton
                  text={t('operationsPage.operationDetails.imageModal.actions.metaData')}
                  startIcon={<MetadataIcon className="rs-action-button__start-icon" />}
                  iconContainerClassName="rs-action-button__icon-container__metadata"
                  data-testid="operation-details-image-modal-metadata-button"
                  onClick={() => alert('metadata action')}
                />
              </div>
            }
            handleClose={() => {
              alert('Close modal');
            }}
          />
          <div className="test-page__modal-header-footer-bar__placeholder"></div>
          <ModalDrawerFooter
            informationBlocks={
              <>
                <InformationBlock
                  label={t('operationsPage.operationDetails.imageModal.result')}
                  // @ts-expect-error - For demo purposes
                  value={<UploadStatusChip uploadStatus="UPLOAD_DONE" />}
                />
                <InformationBlock label={t('operationsPage.operationDetails.imageModal.size')} value="Catsum Ipsum" />
                <InformationBlock
                  label={t('operationsPage.operationDetails.imageModal.fileName')}
                  value="Attack feet behind the couch"
                />
                <InformationBlock
                  label={t('operationsPage.operationDetails.imageModal.createdAt', {
                    timezone: 'CAT+2'
                  })}
                  value="Destroy couch flop over"
                />
              </>
            }
            actions={
              <>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://cdn.pixabay.com/photo/2023/07/04/04/44/ai-generated-8105386_960_720.jpg"
                  data-testid="operation-image-modal-download-link"
                >
                  <RSButton variant="contained" data-testid="operation-image-modal-download-button">
                    {t('operationsPage.operationDetails.imageModal.downloadImage')}
                  </RSButton>
                </a>
              </>
            }
          />
        </div>
      </div>
      <div>
        <h2 className="test-page__division-title">Customer Details Test Page</h2>
        <ExternalLink url="/portal/customers/details-test">Go to the Test Page</ExternalLink>
      </div>
      <div>
        <h2 className="test-page__division-title">Internal Link</h2>
        <InternalLink to="/portal" icon={<ArrowLeftIcon />} text="Go to the Portal" />
      </div>
      <div>
        <h2 className="test-page__division-title">Count Block</h2>
        <CountBlock title="ROCs" count={46} />
      </div>
      <div>
        <h2 className="test-page__division-title">Header Styling</h2>
        <h1>Header H1</h1>
        <br />
        <br />
        <h2>Header H2</h2>
        <br />
        <br />
        <h3>Header H3</h3>
        <br />
        <br />
      </div>
      <div className="test-page__autocomplete">
        <h2 className="test-page__division-title">Autocomplete</h2>
        <p>This div has a white background: the autocomplete component is transparent</p>
        <div className="test-page__autocomplete-container">
          <RSAutocomplete options={dummyMovies} inputLabel="One test" />
        </div>
        <br />
        <p>This one below uses customised menu option rendering:</p>
        <div className="test-page__autocomplete-container">
          <RSAutocomplete customMenuOption={testCustomMenuOption} options={dummyMovies} inputLabel="Some test label" />
        </div>
        <br />
        <p>This one below uses customised menu option and customised tag option rendering:</p>
        <div className="test-page__autocomplete-container">
          <RSAutocomplete
            customMenuOption={testCustomMenuOption}
            customTagOption={testCustomTagOption}
            options={dummyMovies}
            inputLabel="Another test"
          />
        </div>
        <br />
        <p>
          No matter how the tags or menu options get rendered, the values are always the same: that is the `options`.
        </p>
      </div>
      <div>
        <h2 className="test-page__division-title">Information Blocks</h2>
        <div className="test-page__information-blocks">
          <InformationBlock label="Hoi" value="🐻 ik ben een beer" subValue="Wij willen meer beertjes!" />
          <InformationBlock
            label="I have a chip"
            value={<RSChip color="success" label="I have success" icon={<CheckmarkIcon />} />}
            subValue="Some strange remark"
          />
          <InformationBlock
            label="In case you want some cute animals"
            value={
              <ExternalLink url="https://izushaboten.com/kapiroten-en/">Capybaras enjoying hot spring</ExternalLink>
            }
          />
          <InformationBlock
            label="Green Light 🚥"
            value={<IconWithDescription icon={<LightIcon />} color="success" description="You can go 🚗" />}
          />
        </div>
      </div>
      <div>
        <h2 className="test-page__division-title">Sentry test</h2>
        <div className="test-page__sentry-test">
          <RSButton
            variant="contained"
            color="error"
            onClick={() => {
              throw new Error('I am an error');
            }}
          >
            Trigger error
          </RSButton>
        </div>
      </div>
    </div>
  );
};

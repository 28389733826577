import classNames from 'classnames';
import { JSX, ReactNode } from 'react';

import { DataTestId } from '../../../../types';
import { RSButton, RSButtonProps } from '../../../5-elements';
import { RSNavLinkTimezone, RSNavLinkTimezoneProps } from '../../rs-navlink-timezone';

export interface AdminNavMenuButtonProps extends DataTestId {
  key: string;
  buttonLabel: ReactNode;
  navLinkProps: RSNavLinkTimezoneProps;
  buttonProps?: RSButtonProps;
}

export const AdminNavMenuButton = ({
  'data-testid': dataTestId,
  buttonLabel,
  navLinkProps,
  buttonProps
}: AdminNavMenuButtonProps): JSX.Element => {
  const menuButtonClassNames = (isActive: boolean) => {
    return classNames('admin-nav-menu-button', { 'admin-nav-menu-button--active': isActive === true });
  };

  return (
    <RSNavLinkTimezone
      {...navLinkProps}
      className={({ isActive }) => menuButtonClassNames(isActive)}
      data-testid={dataTestId}
    >
      <RSButton {...buttonProps} extraClassNames={['admin-nav-menu-button__button']} color="success">
        {buttonLabel}
      </RSButton>
    </RSNavLinkTimezone>
  );
};

import { CompanyOrderBy, OrderBy } from '../../../../__generated__/graphql';
import { DEFAULT_GET_ADMIN_COMPANIES_SORT_BY } from '../../../../constants';

export enum CompaniesAdminSortOptions {
  NameAsc = 'name',
  NameDesc = '-name',
  TypeAsc = 'type',
  TypeDesc = '-type',
  AllowImportAsc = 'allowImport',
  AllowImportDesc = '-allowImport',
  CreatedAtAsc = 'createdAt',
  CreatedAtDesc = '-createdAt',
  UpdatedAtAsc = 'updatedAt',
  UpdatedAtDesc = '-updatedAt'
}

export const generateSortQuery = (sortOption?: CompaniesAdminSortOptions): CompanyOrderBy[] => {
  switch (sortOption) {
    case CompaniesAdminSortOptions.NameDesc:
      return [{ name: OrderBy.Desc }];
    case CompaniesAdminSortOptions.TypeAsc:
      return [{ companyType: OrderBy.Asc }, ...DEFAULT_GET_ADMIN_COMPANIES_SORT_BY];
    case CompaniesAdminSortOptions.TypeDesc:
      return [{ companyType: OrderBy.Desc }, ...DEFAULT_GET_ADMIN_COMPANIES_SORT_BY];
    case CompaniesAdminSortOptions.AllowImportAsc:
      // First `true`, then `false`, finally `null`
      return [{ customer: { allowImport: OrderBy.DescNullsLast } }, ...DEFAULT_GET_ADMIN_COMPANIES_SORT_BY];
    case CompaniesAdminSortOptions.AllowImportDesc:
      // First `null`, then `false`, finally `true`
      return [{ customer: { allowImport: OrderBy.AscNullsFirst } }, ...DEFAULT_GET_ADMIN_COMPANIES_SORT_BY];
    case CompaniesAdminSortOptions.CreatedAtAsc:
      return [{ createdAt: OrderBy.Asc }, ...DEFAULT_GET_ADMIN_COMPANIES_SORT_BY];
    case CompaniesAdminSortOptions.CreatedAtDesc:
      return [{ createdAt: OrderBy.Desc }, ...DEFAULT_GET_ADMIN_COMPANIES_SORT_BY];
    case CompaniesAdminSortOptions.UpdatedAtAsc:
      return [{ updatedAt: OrderBy.Asc }, ...DEFAULT_GET_ADMIN_COMPANIES_SORT_BY];
    case CompaniesAdminSortOptions.UpdatedAtDesc:
      return [{ updatedAt: OrderBy.Desc }, ...DEFAULT_GET_ADMIN_COMPANIES_SORT_BY];
    case CompaniesAdminSortOptions.NameAsc:
    default:
      return DEFAULT_GET_ADMIN_COMPANIES_SORT_BY;
  }
};

import { UserPermissionType } from '../../__generated__/graphql';
import i18n from '../../i18n';

export const mapUserPermissionType = (permissionType: UserPermissionType): string => {
  switch (permissionType) {
    case UserPermissionType.CompanyAdmin:
      return i18n.t('displayUserPermissionType.companyAdmin');
    case UserPermissionType.DeviceAdmin:
      return i18n.t('displayUserPermissionType.deviceAdmin');
    case UserPermissionType.DeviceImporter:
      return i18n.t('displayUserPermissionType.deviceImporter');
    case UserPermissionType.UserAdmin:
      return i18n.t('displayUserPermissionType.userAdmin');
    case UserPermissionType.UserDeviceGroupAdmin:
      return i18n.t('displayUserPermissionType.userDeviceGroupAdmin');
    default:
      return i18n.t('noData');
  }
};

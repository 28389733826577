import i18n from '../../i18n';
import { DeviceAllowImport } from '../../types';

export const mapDeviceAllowImportDisplayLabel = (allowImport: DeviceAllowImport): string => {
  switch (allowImport) {
    case DeviceAllowImport.Allow:
      return i18n.t('displayDeviceAllowImport.allow');
    case DeviceAllowImport.NotAllow:
      return i18n.t('displayDeviceAllowImport.notAllow');
    default:
      return i18n.t('noData');
  }
};

import { toNumber } from 'lodash';
import { z } from 'zod';

import { CompanyType, DeviceAllowImport } from '../../../../types';
import { PreprocessArgument, preprocessArrayStringInput } from '../../../../utilities';
import { CompaniesAdminSortOptions } from '../generate-queries';

export const companiesAdminStatesSchema = z.object({
  // pagination
  page: z.preprocess((arg) => toNumber(arg), z.number().int().positive()).optional(),
  // sorting
  sort: z.nativeEnum(CompaniesAdminSortOptions).optional(),
  // filtering
  name: z.preprocess((arg) => preprocessArrayStringInput(arg as PreprocessArgument), z.string().array().optional()),
  type: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.nativeEnum(CompanyType).array().optional()
  ),
  allowImport: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.nativeEnum(DeviceAllowImport).array().optional()
  )
});

export type CompaniesAdminSearchParameters = z.infer<typeof companiesAdminStatesSchema>;

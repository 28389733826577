import { toNumber } from 'lodash';
import { z } from 'zod';

import { DeviceDeactivated } from '../../../../types';
import { PreprocessArgument, preprocessArrayStringInput } from '../../../../utilities';
import { DevicesAdminSortOptions } from '../generate-queries';

export const devicesAdminStatesSchema = z.object({
  // pagination
  page: z.preprocess((arg) => toNumber(arg), z.number().int().positive()).optional(),
  // sorting
  sort: z.nativeEnum(DevicesAdminSortOptions).optional(),
  // filtering
  serialNumber: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.string().array().optional()
  ),
  company: z.preprocess((arg) => preprocessArrayStringInput(arg as PreprocessArgument), z.string().array().optional()),
  site: z.preprocess((arg) => preprocessArrayStringInput(arg as PreprocessArgument), z.string().array().optional()),
  program: z.preprocess((arg) => preprocessArrayStringInput(arg as PreprocessArgument), z.string().array().optional()),
  type: z.preprocess((arg) => preprocessArrayStringInput(arg as PreprocessArgument), z.string().array().optional()),
  connectorHolderType: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.string().array().optional()
  ),
  deactivated: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.nativeEnum(DeviceDeactivated).array().optional()
  )
});

export type DevicesAdminSearchParameters = z.infer<typeof devicesAdminStatesSchema>;

import { faker } from '@faker-js/faker';

import { GetAdminUsersQuery, UserPermissionType, UserType } from '../../../__generated__/graphql';
import { CompanyType } from '../../../types';

const fakerGeneratedAdminUsers = (): GetAdminUsersQuery['users'] => {
  const users: GetAdminUsersQuery['users'] = [];
  for (let i = 0; i < 90; i += 1) {
    users.push({
      id: faker.string.uuid(),
      firstName: faker.person.firstName(),
      lastName: faker.person.lastName(),
      email: faker.internet.email(),
      userType: faker.helpers.enumValue(UserType),
      isSuperUser: faker.datatype.boolean(),
      userPermissionLinks: [{ id: faker.string.uuid(), userPermission: faker.helpers.enumValue(UserPermissionType) }],
      userDeviceGroupsAggregate: { aggregate: { count: faker.number.int({ max: 10, min: 0 }) } },
      createdAt: faker.date.past().toISOString(),
      updatedAt: faker.date.past().toISOString(),
      company: {
        id: faker.string.uuid(),
        name: faker.company.name(),
        companyType: faker.helpers.enumValue(CompanyType)
      }
    });
  }
  return users;
};

export const fakerGeneratedAdminUsersResponse: GetAdminUsersQuery = {
  usersAggregate: { aggregate: { count: 90 } },
  users: fakerGeneratedAdminUsers()
};

export const getAdminUsersResponse: GetAdminUsersQuery = {
  users: [
    {
      id: '03337c99-60f8-4b2f-9a62-cef1542f3543',
      firstName: 'Aa',
      lastName: 'Good',
      email: 'auser@goodev.com',
      userType: 'PORTAL_USER',
      isSuperUser: false,
      userPermissionLinks: [],
      userDeviceGroupsAggregate: { aggregate: { count: 0 } },
      createdAt: '2024-12-11T08:52:32.573+00:00',
      updatedAt: '2024-12-11T08:52:32.573+00:00',
      company: { id: '957cd1dc-0ed6-44cf-a658-042d42a04820', name: 'Unsed service', companyType: 'SERVICE_PROVIDER' }
    },
    {
      id: '1a7a5bbe-242a-403d-a2e2-54a11f9f80cd',
      firstName: 'Arjan',
      lastName: 'Verschoor',
      email: 'averschoor@rocsys.com',
      userType: 'PORTAL_USER',
      isSuperUser: true,
      userPermissionLinks: [],
      userDeviceGroupsAggregate: { aggregate: { count: 1 } },
      createdAt: '2024-12-11T08:52:32.566+00:00',
      updatedAt: '2024-12-11T08:52:32.566+00:00',
      company: {
        id: '387fce34-d8af-40de-a820-243931f6392f',
        name: 'Rocsys Service & Applications',
        companyType: 'SERVICE_PROVIDER'
      }
    },
    {
      id: '235c3678-ddd1-41df-81ed-d8e44c20a5ba',
      firstName: 'Ruben',
      lastName: 'Keulemans',
      email: 'rkeulemans@rocsys.com',
      userType: 'PORTAL_USER',
      isSuperUser: true,
      userPermissionLinks: [],
      userDeviceGroupsAggregate: { aggregate: { count: 1 } },
      createdAt: '2024-12-11T08:52:32.57+00:00',
      updatedAt: '2024-12-11T08:52:32.57+00:00',
      company: {
        id: '4fea8c96-c719-463d-a021-0d6335246fff',
        name: 'Rocsys Production Factory',
        companyType: 'CUSTOMER'
      }
    },
    {
      id: '1c7632bc-c7a7-4c57-b371-80000555ec37',
      firstName: 'Smoke API',
      lastName: 'Test API',
      email: 'cloud-integration+smoketest@rocsys.com',
      userType: 'ROCSYS_API_USER',
      isSuperUser: true,
      userPermissionLinks: [],
      userDeviceGroupsAggregate: { aggregate: { count: 0 } },
      createdAt: '2024-12-11T08:52:32.586+00:00',
      updatedAt: '2024-12-11T08:52:32.586+00:00',
      company: {
        id: '4fea8c96-c719-463d-a021-0d6335246fff',
        name: 'Rocsys Production Factory',
        companyType: 'CUSTOMER'
      }
    },
    {
      id: 'ff0960c0-e879-46a7-bfc1-62e28a91480c',
      firstName: 'Xiaozhou',
      lastName: 'Li',
      email: 'xli@rocsys.com',
      userType: 'PORTAL_USER',
      isSuperUser: true,
      userPermissionLinks: [
        { id: '5a81cfbe-b9f0-4f6b-af3d-d86b10c70d03', userPermission: 'COMPANY_ADMIN' },
        { id: '63c507e1-9bf4-434a-a94b-035bffb59dfb', userPermission: 'DEVICE_ADMIN' },
        { id: 'df4a82a3-07c4-401c-a186-ecf8cb7216cc', userPermission: 'USER_ADMIN' },
        { id: '8c7aff71-7632-42a8-b4d7-6d2d01b6eb95', userPermission: 'USER_DEVICE_GROUP_ADMIN' },
        { id: '60f4a756-881e-42a9-9479-65f3f8132608', userPermission: 'DEVICE_IMPORTER' }
      ],
      userDeviceGroupsAggregate: { aggregate: { count: 0 } },
      createdAt: '2024-12-11T08:52:32.574+00:00',
      updatedAt: '2024-12-11T11:10:38.142+00:00',
      company: {
        id: '4fea8c96-c719-463d-a021-0d6335246fff',
        name: 'Rocsys Production Factory',
        companyType: 'CUSTOMER'
      }
    }
  ],
  usersAggregate: { aggregate: { count: 5 } }
};

import { useApolloClient } from '@apollo/client';
import { JSX, useContext } from 'react';

import {
  GetAllDeviceOperationsPerformanceQuery,
  GetAllDeviceOperationsPerformanceQueryVariables,
  SubscribeOperationsAggregateSubscription
} from '../../../../__generated__/graphql';
import { QUERY_GET_ALL_DEVICE_OPERATIONS_PERFORMANCE } from '../../../../services/queries';
import { calculateSuccessPercentage, execIfBothDefined } from '../../../../utilities';
import { PerformanceMetricsRender } from '../../../4-features';
import { SubscriptionModeContext } from '../../../contexts';

interface OperationPerformanceMetricsProps {
  performanceQueryVariables: GetAllDeviceOperationsPerformanceQueryVariables;
  loadingPerformance: boolean;
  errorPerformance: boolean;
  data?: GetAllDeviceOperationsPerformanceQuery;
  dataSubscriptionSuccess?: SubscribeOperationsAggregateSubscription;
  dataSubscriptionFailed?: SubscribeOperationsAggregateSubscription;
}

export const OperationPerformanceMetrics = ({
  performanceQueryVariables,
  loadingPerformance,
  errorPerformance,
  data,
  dataSubscriptionSuccess,
  dataSubscriptionFailed
}: OperationPerformanceMetricsProps): JSX.Element => {
  const { subscriptionMode } = useContext(SubscriptionModeContext);
  const apolloClient = useApolloClient();
  const cachedData = apolloClient.readQuery({
    query: QUERY_GET_ALL_DEVICE_OPERATIONS_PERFORMANCE,
    variables: performanceQueryVariables
  });
  const acdCycleOperationsSuccess =
    (subscriptionMode
      ? dataSubscriptionSuccess?.deviceOperationsAggregate.aggregate?.count ||
        cachedData?.totalACDCycleOperationsSuccess.aggregate?.count
      : data?.totalACDCycleOperationsSuccess.aggregate?.count) || 0;
  const acdCycleOperationsFailed =
    (subscriptionMode
      ? dataSubscriptionFailed?.deviceOperationsAggregate.aggregate?.count ||
        cachedData?.totalACDCycleOperationsFailed.aggregate?.count
      : data?.totalACDCycleOperationsFailed.aggregate?.count) || 0;
  const acdCycleOperationsSuccessRate = calculateSuccessPercentage(acdCycleOperationsFailed, acdCycleOperationsSuccess);
  const acdCycleOperationsCount = execIfBothDefined(
    acdCycleOperationsSuccess,
    acdCycleOperationsFailed,
    (val1, val2) => val1 + val2
  );

  return (
    <PerformanceMetricsRender
      acdCycleOperationsSuccessRate={acdCycleOperationsSuccessRate}
      acdCycleOperationsCount={acdCycleOperationsCount}
      loadingTotalAcdCyclesOperationResult={loadingPerformance}
      errorTotalAcdCyclesOperationResult={errorPerformance}
      isRawPerformance={true}
    />
  );
};

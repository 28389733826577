import { gql } from '../../../__generated__/gql';

export const SUBSCRIPTION_OPERATION_DETAILS = gql(`
  subscription subscribeOperationDetails($operationId: uuid!) {
    deviceOperationByPK(id: $operationId) {
      id
      startAt
      endAt
      operationName
      operationalLifeCycle
      initialOperationalLifeCycle
      remark
      success
      sequence
      device {
        id
        serialNumber
        customer {
          companyId
          company {
            id
            name
          }
        }
        site {
          id
          name
        }
        program {
          id
          name
        }
      }
      deviceOperations(orderBy: { startAt: ASC }) {
        id
        operationName
        success
        startAt
        endAt
        metaData
        deviceOperationFinalResult {
          id
          name
          code
          isSuccessful
          updatedAt
        }
        deviceOperationResult {
          id
          name
          isSuccessful
          updatedAt
          code
        }
        deviceOperationAnnotatedResult {
          id
          name
          isSuccessful
          updatedAt
          code
        }
        deviceOperationLog {
          id
          createdAt
          autoTriggered
          requestTries
          size
          status
        }
        deviceOperationImages(orderBy: { recordedAt: ASC }) {
          id
          fileName
          recordedAt
          sequence
          size
          status
          type
          visualType
          metaData
        }
      }
      deviceOperationLog {
        id
        createdAt
        autoTriggered
        requestTries
        size
        status
      }
      deviceOperationFinalResult {
        id
        name
        isSuccessful
        updatedAt
        code
      }
      deviceOperationResult {
        id
        name
        isSuccessful
        updatedAt
        code
      }
      deviceOperationAnnotatedResult {
        id
        name
        isSuccessful
        updatedAt
        code
      }
      deviceOperationAnnotationAudits(orderBy: { annotatedAt: DESC }) {
        id
        remark
        operationalLifeCycle
        annotatedAt
        annotatedByUser {
          id
          firstName
          lastName
        }
        deviceOperationResult {
          id
          name
        }
      }
    }
  }
`);

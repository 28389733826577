import { DeviceOperationOrderBy, OrderBy } from '../../../../__generated__/graphql';
import { DEFAULT_GET_OPERATIONS_SORT_BY } from '../../../../constants';

export enum OperationsOverviewSortOptions {
  SerialNumberAsc = 'serialNumber',
  SerialNumberDesc = '-serialNumber',
  ResultAsc = 'result',
  ResultDesc = '-result',
  DateTimeAsc = 'dateTime',
  DateTimeDesc = '-dateTime',
  OperationalLifeCycleAsc = 'operationalLifeCycle',
  OperationalLifeCycleDesc = '-operationalLifeCycle',
  CustomerAsc = 'customer',
  CustomerDesc = '-customer',
  SiteAsc = 'site',
  SiteDesc = '-site',
  ProgramAsc = 'program',
  ProgramDesc = '-program'
}

export const generateSortQuery = (sortOption?: OperationsOverviewSortOptions): DeviceOperationOrderBy[] => {
  switch (sortOption) {
    case OperationsOverviewSortOptions.SerialNumberAsc:
      return [{ device: { serialNumber: OrderBy.Asc } }, DEFAULT_GET_OPERATIONS_SORT_BY[0]];
    case OperationsOverviewSortOptions.SerialNumberDesc:
      return [{ device: { serialNumber: OrderBy.Desc } }, DEFAULT_GET_OPERATIONS_SORT_BY[0]];
    case OperationsOverviewSortOptions.ResultAsc:
      return [
        { deviceOperationFinalResult: { isSuccessful: OrderBy.DescNullsFirst } },
        { deviceOperationFinalResult: { name: OrderBy.Asc } },
        DEFAULT_GET_OPERATIONS_SORT_BY[0]
      ];
    case OperationsOverviewSortOptions.ResultDesc:
      return [
        { deviceOperationFinalResult: { isSuccessful: OrderBy.AscNullsLast } },
        { deviceOperationFinalResult: { name: OrderBy.Desc } },
        DEFAULT_GET_OPERATIONS_SORT_BY[0]
      ];
    case OperationsOverviewSortOptions.DateTimeAsc:
      return [{ startAt: OrderBy.Asc }];
    case OperationsOverviewSortOptions.DateTimeDesc:
      return DEFAULT_GET_OPERATIONS_SORT_BY;
    case OperationsOverviewSortOptions.OperationalLifeCycleAsc:
      return [{ operationalLifeCycle: OrderBy.Asc }, DEFAULT_GET_OPERATIONS_SORT_BY[0]];
    case OperationsOverviewSortOptions.OperationalLifeCycleDesc:
      return [{ operationalLifeCycle: OrderBy.Desc }, DEFAULT_GET_OPERATIONS_SORT_BY[0]];
    case OperationsOverviewSortOptions.CustomerAsc:
      return [
        { device: { site: { customer: { company: { name: OrderBy.Asc } } } } },
        DEFAULT_GET_OPERATIONS_SORT_BY[0]
      ];
    case OperationsOverviewSortOptions.CustomerDesc:
      return [
        { device: { site: { customer: { company: { name: OrderBy.Desc } } } } },
        DEFAULT_GET_OPERATIONS_SORT_BY[0]
      ];
    case OperationsOverviewSortOptions.SiteAsc:
      return [{ device: { site: { name: OrderBy.Asc } } }, DEFAULT_GET_OPERATIONS_SORT_BY[0]];
    case OperationsOverviewSortOptions.SiteDesc:
      return [{ device: { site: { name: OrderBy.Desc } } }, DEFAULT_GET_OPERATIONS_SORT_BY[0]];
    case OperationsOverviewSortOptions.ProgramAsc:
      return [{ device: { program: { name: OrderBy.Asc } } }, DEFAULT_GET_OPERATIONS_SORT_BY[0]];
    case OperationsOverviewSortOptions.ProgramDesc:
      return [{ device: { program: { name: OrderBy.Desc } } }, DEFAULT_GET_OPERATIONS_SORT_BY[0]];
    default:
      return DEFAULT_GET_OPERATIONS_SORT_BY;
  }
};

import { CustomerPrograms, FlattenFunction } from '../../../../../types';
import { getPerformanceMetricsValues } from '../../../../../utilities';

export type CustomerProgramRow = FlattenFunction<typeof generateCustomerProgramRows>;

export const generateCustomerProgramRows = (programs?: CustomerPrograms) => {
  if (!programs) {
    return [];
  }

  return programs.map((program) => ({
    id: program.id,
    name: program.name,
    performance: getPerformanceMetricsValues(
      program.programCurrentPerformance?.successLastPeriod ?? null,
      program.programCurrentPerformance?.totalLastPeriod ?? null,
      program.programCurrentPerformance?.successPrevPeriod ?? null,
      program.programCurrentPerformance?.totalPrevPeriod ?? null
    ),
    rocs: program.devicesAggregate.aggregate?.count || 0
  }));
};
